import { useCallback } from 'react';
import axios from 'axios';
import { useLoader } from '../../../hooks/useLoader';
import { MailContentTemplate, UpdateEmailTemplateResponse, UseDeleteEmailTemplateResult } from '../types';

export type UseDeleteEmailTemplateOpts = {
  apiBaseUrl: string;
  employerId: string;
  emailType: string;
  onTemplateDeleted?: (template: MailContentTemplate) => void;
};

export const useDeleteEmailTemplate = ({
  apiBaseUrl,
  employerId,
  onTemplateDeleted,
  emailType,
}: UseDeleteEmailTemplateOpts): UseDeleteEmailTemplateResult => {
  const { loading, error, run, setError } = useLoader({ cancelOnUnmount: false });
  const deleteEmailTemplate = useCallback(
    (template: MailContentTemplate) => {
      run(
        axios.delete<UpdateEmailTemplateResponse>(
          `/api/employer/${employerId}/email-templates/${emailType}/${template.locale}`,
          {
            baseURL: apiBaseUrl,
          },
        ),
      )
        ?.then((result) => {
          if (!result) {
            // Cancelled
            return;
          }

          if (result?.data?.success && onTemplateDeleted) {
            onTemplateDeleted(template);
          } else if (!result.data?.success) {
            setError(result.data?.error || 'Unknown Error');
          }
        })
        ?.catch((error: Error) => {
          setError(error?.message || error?.toString() || 'Unknown error');
        });
    },
    [apiBaseUrl, employerId, emailType, run, onTemplateDeleted, setError],
  );

  return {
    error,
    loading,
    deleteEmailTemplate,
  };
};
