import { HTMLAttributes, PropsWithChildren } from 'react';

export type GridRowProps = HTMLAttributes<unknown>;

export function GridRow({ children, className }: PropsWithChildren<GridRowProps>) {
  const classes = ['grid__row'];
  if (className) classes.push(className);

  return <div className={`${classes.join(' ')}`}>{children}</div>;
}
