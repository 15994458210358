import React from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { FaChevronDown } from 'react-icons/fa';
import { Icon } from '../icon/icon';

export type AccordionTriggerProps = { title: string | JSX.Element | React.ReactNode };

export const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ title }: AccordionTriggerProps, forwardedRef) => (
    <Accordion.Header className="accordion-header">
      <Accordion.Trigger className="accordion-trigger" ref={forwardedRef}>
        <span className="font-medium accordion-title">{title}</span>
        <Icon icon={FaChevronDown} className="accordion-chevron" aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  ),
);
