import React, { TextareaHTMLAttributes } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';

export type FormTextareaProps = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  'name' | 'value' | 'onChange' | 'onBlur'
> &
  GenericFormInputProps & {
    enforceMaxLength?: boolean;
    showError?: boolean;
  };

export const FormTextarea = ({
  containerClassName = 'form-textarea-container',
  containerTestId,
  name,
  label,
  tooltip,
  required,
  optional,
  placeholder,
  testId,
  renderBefore,
  renderAfter,
  maxLength,
  showError = true,
  enforceMaxLength = true,
  ...props
}: FormTextareaProps) => {
  const [{ value, onChange, onBlur }, { error, touched }] = useField(name);

  return (
    <FormInputContainer
      name={name}
      error={showError ? error : undefined}
      errorPosition={maxLength ? 'before' : 'after'}
      touched={touched}
      label={label}
      tooltip={tooltip}
      required={required}
      optional={optional}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      <fieldset>
        <textarea
          data-testid={testId || name}
          className="u-full-width form-input"
          placeholder={placeholder}
          aria-label={label}
          maxLength={enforceMaxLength ? maxLength : undefined}
          {...props}
          id={name}
          name={name}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
        />
      </fieldset>
      {maxLength && (
        <div className="max-characters">
          <span>{maxLength} characters max</span>
          <span className={`count ${maxLength && value?.length > maxLength ? 'error' : ''}`}>{value?.length || 0}</span>
        </div>
      )}
    </FormInputContainer>
  );
};
