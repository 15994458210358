import React, { PropsWithChildren, ReactNode } from 'react';
import { Footer } from '../footer/footer';
import { CookieConsent } from '../../cookie-consent/cookie-consent';
import classnames from 'classnames';

export type AppWrapperProps = PropsWithChildren<{
  header?: ReactNode;
  footer?: ReactNode | false;
  mobileFullWidth?: boolean;
  padTop?: boolean;
  wrapperClassName?: string;
  links?: {
    privacy: string;
    terms: string;
  };
}>;

export const AppWrapper = ({
  wrapperClassName,
  children,
  header,
  footer = <Footer />,
  mobileFullWidth,
  padTop = true,
  links,
}: AppWrapperProps) => {
  return (
    <main className={classnames('app-wrapper', wrapperClassName)}>
      {header || null}

      <div className={classnames('content-wrapper', { 'mobile-full-width': mobileFullWidth })}>
        <div className={'main-content short' + (!padTop ? ' no-padding' : '')}>{children}</div>
      </div>

      {footer || null}
      {links ? <CookieConsent privacyLink={links.privacy} termsLink={links.terms} /> : null}
    </main>
  );
};
