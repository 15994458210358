import React, { useCallback, useEffect, useMemo } from 'react';
import { DatagridColumn, DatagridObject, DatagridProps } from './types';
import { DatagridTableHead } from './datagrid-table-head';
import { DatagridPager } from './datagrid-pager';
import { DatagridHead } from './datagrid-head';
import { DatagridTableBody } from './datagrid-table-body';
import { DatagridSelectBox } from './datagrid-select-box';
import { Box } from '../layout/box/box';
import classnames from 'classnames';

export const Datagrid = <T extends DatagridObject, Sort, Filter = any>(props: DatagridProps<T, Sort, Filter>) => {
  const rowIdField = props.idColumn || '_id';
  const [selectedRowIds, setSelectedRowIds] = React.useState<T['_id'][]>([]);
  const TableHeadComponent = props.tableHeadComponent !== false ? props.tableHeadComponent || DatagridTableHead : null;
  const TableBodyComponent = props.tableBodyComponent || DatagridTableBody;
  const PagerComponent = props.pagerComponent || DatagridPager;
  const HeadComponent = props.headComponent || DatagridHead;

  const onColumnChecked = useCallback(
    (checked: boolean, row: T) => {
      const updatedRowIds = checked
        ? [...selectedRowIds, row[rowIdField]]
        : selectedRowIds.filter((r) => r !== row[rowIdField]);
      setSelectedRowIds(updatedRowIds);
      props.onSelectedChange?.(updatedRowIds);
    },
    [props.onSelectedChange, selectedRowIds, setSelectedRowIds],
  );

  const selectableIds = useMemo(() => {
    if (!props.bulkActions && !props.onSelectedChange) {
      return [];
    }

    if (props.isSelectable) {
      return props.data.filter(props.isSelectable).map((r) => r[rowIdField]);
    }

    return props.data.map((r) => r[rowIdField]);
  }, [props.onSelectedChange, props.bulkActions, props.data, props.isSelectable]);

  const onSelectAllChecked = useCallback(
    (checked: boolean) => {
      const updatedRowIds = checked ? selectableIds : [];
      setSelectedRowIds(updatedRowIds);
      props.onSelectedChange?.(updatedRowIds);
    },
    [props.onSelectedChange, selectableIds],
  );

  const clearSelected = useCallback(() => {
    setSelectedRowIds([]);
    props.onSelectedChange?.([]);
  }, [props.onSelectedChange]);

  const allChecked = useMemo(() => {
    if (selectedRowIds.length === 0 || selectableIds.length === 0) {
      return false;
    }

    return selectedRowIds.length === selectableIds.length;
  }, [selectableIds.length, selectedRowIds.length]);

  // When the data changes, clear the selected rows
  useEffect(() => {
    const selectedRowIdsThatStillExist = selectedRowIds.filter((id) => selectableIds.includes(id));
    if (selectedRowIdsThatStillExist.length !== selectedRowIds.length) {
      console.log('Updating selected row ids because the data changed');
      setSelectedRowIds(selectedRowIdsThatStillExist);
      props.onSelectedChange?.(selectedRowIdsThatStillExist);
    }
  }, [selectableIds, selectedRowIds]);

  const selectable = (typeof props.bulkActions !== 'undefined' && selectableIds.length > 0) || props.isSelectable;
  const columns: Array<DatagridColumn<T, Sort>> = useMemo(() => {
    if (selectable) {
      const selectHeader = <DatagridSelectBox onChecked={onSelectAllChecked} checked={allChecked} />;

      const selectColumn: DatagridColumn<T, Sort> = {
        header: selectHeader,
        sortable: false,
        accessor: (row) => {
          if (!selectableIds.includes(row[rowIdField])) {
            return null;
          }

          return (
            <DatagridSelectBox
              onChecked={(checked) => onColumnChecked(checked, row)}
              checked={selectedRowIds.includes(row[rowIdField])}
            />
          );
        },
      };
      return [selectColumn, ...props.columns];
    }

    return props.columns;
  }, [
    selectable,
    props.columns,
    onSelectAllChecked,
    allChecked,
    selectableIds,
    selectedRowIds,
    onColumnChecked,
    rowIdField,
  ]);

  const actions = useMemo(() => {
    if (props.bulkActions && (selectedRowIds.length || !props.actions)) {
      return props.bulkActions(selectedRowIds, clearSelected);
    }

    return props.actions;
  }, [props.actions, props.bulkActions, selectedRowIds, selectable]);

  return (
    <div className={classnames('datagrid-container', props.className)}>
      <div className="datagrid-inner-container">
        {props.showTableHead !== false ? (
          props.filterable ? (
            <HeadComponent
              onSearchChange={props.onSearchChange}
              actions={actions}
              filterable={props.filterable}
              filterState={props.filterState}
              filterForm={props.filterForm}
              onFilterChange={props.onFilterChange}
            />
          ) : props.onSearchChange || actions ? (
            <HeadComponent onSearchChange={props.onSearchChange} actions={actions} />
          ) : null
        ) : null}

        <Box className="datagrid-table-container">
          <table data-testid={props.testId}>
            {props.showTableHead !== false && TableHeadComponent ? (
              props.sortable ? (
                <TableHeadComponent<T, Sort>
                  columns={columns}
                  sortable={props.sortable}
                  onSortChange={props.onSortChange}
                  sortState={props.sortState}
                  expandable={!!props.expandComponent}
                />
              ) : (
                <TableHeadComponent<T, Sort> columns={columns} expandable={!!props.expandComponent} />
              )
            ) : null}

            <TableBodyComponent<T, Sort>
              columns={columns}
              data={props.data}
              idColumn={props.idColumn}
              loading={props.loading}
              noDataText={props.noDataText}
              loadingText={props.loadingText}
              onClickRow={props.onClickRow}
              expandComponent={props.expandComponent}
              canExpand={props.canExpand}
              ghostLoad={props.ghostLoad}
            />
          </table>
        </Box>
      </div>

      {props.pagination === true && PagerComponent && props.totalRecords > 0 ? (
        <PagerComponent
          paginationState={props.paginationState}
          totalRecords={props.totalRecords}
          onPaginationChange={props.onPaginationChange}
        />
      ) : null}
    </div>
  );
};
