import { useCallback } from 'react';
import axios from 'axios';
import { CreateFolderOpts, CreateMediaFolderResponse, MediaFolder, UseCreateFolderResult } from '../types';
import { useLoader } from '../../../hooks/useLoader';

export type UseCreateFolderOpts = {
  apiBaseUrl: string;
  employerId: string;
  onFolderCreated: (folder: MediaFolder) => void;
};

export const useCreateFolder = ({
  apiBaseUrl,
  employerId,
  onFolderCreated,
}: UseCreateFolderOpts): UseCreateFolderResult => {
  const { loading, error, run, setError } = useLoader({ cancelOnUnmount: false });

  const createFolder = useCallback(
    ({ parentId, name }: CreateFolderOpts) => {
      run(
        axios.post<CreateMediaFolderResponse>(
          `/api/employer/${employerId}/media-folders`,
          { parentId, name },
          {
            baseURL: apiBaseUrl,
          },
        ),
      )
        ?.then((result) => {
          if (!result) {
            // Cancelled
            return;
          }

          if (result.data?.success && onFolderCreated) {
            onFolderCreated(result.data.folder);
          } else if (!result.data?.success) {
            setError(result.data?.error || 'Unknown Error');
          }
        })
        ?.catch((error: Error) => {
          setError(error?.message || error?.toString() || 'Unknown error');
        });
    },
    [apiBaseUrl, employerId, run, onFolderCreated, setError],
  );

  return {
    error,
    loading,
    createFolder,
  };
};
