import { MediaItemResponse } from '../types';
import React, { MouseEventHandler } from 'react';
import { Grid } from '../../core/layout/grid/grid';

export interface MediaItemGridProps {
  items: MediaItemResponse[];
  onClickMediaItem: (item: MediaItemResponse) => void;
}

export function MediaItemGrid({ items, onClickMediaItem }: MediaItemGridProps) {
  if (!items.length) {
    return null;
  }

  const onClick =
    (item: MediaItemResponse): MouseEventHandler =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      onClickMediaItem(item);
    };

  return (
    <Grid.Container fluid className="media-items" data-testid="media-items">
      <Grid.Row>
        {items.map((item) => (
          <Grid.Column
            key={item._id}
            colspan={6}
            colspanSm={6}
            colspanMd={4}
            colspanLg={3}
            colspanXl={2}
            onClick={onClick(item)}
          >
            <div className="media-item" data-testid="media-item">
              <img src={item.publicUrl} alt={item.name} />
              <a onClick={onClick(item)} href="#">
                {item.name}
              </a>
            </div>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid.Container>
  );
}
