import { PropsWithChildren } from 'react';
import { DropzoneUpload, DropzoneUploadProps } from '../../core';
import { UseCreateMediaItemOpts } from '../hooks/useCreateMediaItem';
import { MediaBackendAdapter } from '../types';

export type MediaItemDropzoneUploadProps = Pick<DropzoneUploadProps, 'className'> &
  Pick<UseCreateMediaItemOpts, 'folderId' | 'onMediaItemCreated'> & {
    backendAdapter: MediaBackendAdapter;
  };

export const MediaItemDropzoneUpload = ({
  backendAdapter,
  folderId,
  onMediaItemCreated,
  className,
  children,
}: PropsWithChildren<MediaItemDropzoneUploadProps>) => {
  const { createMediaItem } = backendAdapter.useCreateMediaItem({
    folderId,
    onMediaItemCreated,
  });

  const onFileSelected = (image: File) => createMediaItem({ image });
  return (
    <DropzoneUpload
      onFileSelected={onFileSelected}
      accept={{ 'image/jpeg': [], 'image/png': [] }}
      className={className}
    >
      {children}
    </DropzoneUpload>
  );
};
