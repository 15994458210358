import React from 'react';
import { EmailTemplatesBackendAdapter, MailContentTemplate } from './types';
import { CreateEmailTemplateButton } from './create-email-template-button';
import { EmailTemplateList } from './email-template-list';
import { ButtonColor, ButtonPull } from '../core/button/button';
import { ButtonSize } from '../core/button/button';
import { useTranslation } from 'next-i18next';

export type FollowupEmailTemplateListProps = {
  onClickTemplate: (folder: MailContentTemplate) => void;
  emailType: string;
  backendAdapter: EmailTemplatesBackendAdapter;
};

export const FollowupEmailTemplateList = ({
  onClickTemplate,
  emailType,
  backendAdapter,
}: FollowupEmailTemplateListProps) => {
  const { t } = useTranslation();
  const { templates, reload } = backendAdapter.useEmailTemplates({ emailType });
  const { deleteEmailTemplate } = backendAdapter.useDeleteEmailTemplate({
    emailType,
    onTemplateDeleted: reload,
  });

  if (!templates) {
    return null;
  }

  return (
    <>
      <EmailTemplateList
        templates={templates}
        onEditTemplate={onClickTemplate}
        onDeleteTemplate={deleteEmailTemplate}
      />
      <CreateEmailTemplateButton
        size={ButtonSize.Small}
        fullWidth={true}
        color={ButtonColor.Primary}
        pull={ButtonPull.Right}
        emailType={emailType}
        templates={templates}
        onTemplateUpdated={(template) => {
          reload().then(() => {
            onClickTemplate(template);
          });
        }}
        backendAdapter={backendAdapter}
        className="u-mt-2"
      >
        {t('emailTemplates.addNewLocale')}
      </CreateEmailTemplateButton>
    </>
  );
};
