import React, { InputHTMLAttributes, useMemo } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';
import { DatePicker, DatePickerProps } from '../datepicker/datepicker';
import dayjs from 'dayjs';
import { Icon } from '../icon/icon';
import classnames from 'classnames';

export type FormDateInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'name' | 'value' | 'onChange' | 'onBlur'> &
  GenericFormInputProps & {
    datePickerProps: Partial<DatePickerProps>;
    valueFormat?: string;
    datePickerContainerClassName?: string;
  };

export const FormDateInput = ({
  containerClassName,
  datePickerContainerClassName,
  containerTestId,
  autoFocus,
  name,
  label,
  required,
  placeholder,
  testId,
  renderBefore,
  renderAfter,
  valueFormat,
  datePickerProps = {},
  icon,
}: FormDateInputProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);

  const onChange = (date: Date | null) => {
    let newValue: string | number | Date | null = date
      ? valueFormat
        ? dayjs(date).format(valueFormat)
        : dayjs(date).toDate()
      : null;

    if (typeof newValue === 'string' && valueFormat === 'YYYY') {
      newValue = parseInt(newValue);
    }

    setValue(newValue);

    setTimeout(() => {
      setTouched(true);
    }, 10);
  };

  const onClose = () => {
    setTouched(true);
  };

  const valueAsDate = useMemo<Date | undefined>(() => {
    if (!value) return undefined;

    // If value is Date object, return it
    if (value instanceof Date) return value;

    const validFormats = [
      // ISO formats
      'YYYY-MM-DD',
      'YYYY-MM-DDTHH:mm:ssZ',
      'YYYY-MM-DDTHH:mm:ss.SSSZ',
    ];

    // Plus any custom formats
    if (valueFormat) {
      validFormats.push(valueFormat);
    }

    return dayjs(value, validFormats).toDate();
  }, [valueFormat, value]);

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      label={label}
      required={required}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
      icon={icon}
    >
      <fieldset className={classnames({ 'with-datepicker-icon': !!icon })}>
        <DatePicker
          type="date"
          name={name}
          onChange={onChange}
          selected={valueAsDate}
          placeholderText={placeholder}
          className="u-full-width"
          autoFocus={autoFocus}
          containerClassName={datePickerContainerClassName}
          onClose={onClose}
          {...datePickerProps}
        />
        {icon ? <Icon icon={icon} className="form-input-icon" /> : null}
      </fieldset>
    </FormInputContainer>
  );
};
