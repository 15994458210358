import React, { Fragment, MouseEventHandler } from 'react';
import { MediaFolder } from '../types';

type MediaFolderBreadcrumbProps = {
  parentFolders?: MediaFolder[];
  currentFolder?: MediaFolder | undefined;
  onClickFolder?: (folder: MediaFolder | undefined) => void;
};

type BreadcrumbProps = {
  folder: MediaFolder | undefined;
};

type ParentBreadcrumbProps = Pick<MediaFolderBreadcrumbProps, 'onClickFolder'> & BreadcrumbProps;

const ParentBreadcrumb = ({ onClickFolder, folder }: ParentBreadcrumbProps) => {
  const onClick: MouseEventHandler = (e) => {
    e.preventDefault();

    if (onClickFolder) {
      onClickFolder(folder);
    }
  };

  return (
    <a data-testid="breadcrumb-link" className="breadcrumb-link" onClick={onClick} href="#">
      {folder?.name || 'Home'}
    </a>
  );
};

const CurrentBreadcrumb = ({ folder }: BreadcrumbProps) => (
  <span data-testid="breadcrumb-current" className="breadcrumb-current">
    {folder?.name || 'Home'}
  </span>
);

const BreadcrumbDivider = () => <span>{' / '}</span>;

export const MediaFolderBreadcrumb = ({
  parentFolders = [],
  currentFolder = undefined,
  onClickFolder,
}: MediaFolderBreadcrumbProps) => {
  if (!currentFolder) {
    return null;
  }

  return (
    <div data-testid="breadcrumb-container" className="breadcrumb-container">
      {currentFolder ? (
        <Fragment>
          <ParentBreadcrumb folder={undefined} onClickFolder={onClickFolder} />
          <BreadcrumbDivider />
        </Fragment>
      ) : null}

      {parentFolders?.length > 0
        ? parentFolders.map((parentFolder) => (
            <Fragment key={parentFolder._id}>
              <ParentBreadcrumb folder={parentFolder} onClickFolder={onClickFolder} />
              <BreadcrumbDivider />
            </Fragment>
          ))
        : null}

      <CurrentBreadcrumb folder={currentFolder} />
    </div>
  );
};
