import React from 'react';
import { useField } from 'formik';
import { FormInputContainer } from '../form-input-container';
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions';
import { FormTextareaProps } from '../form-textarea';
import classNames from './form-mention-textarea.module.scss';

export type MentionData = {
  userId: string;
  name: string;
};

export type FormMentionTextareaProps = Omit<FormTextareaProps, 'onSelect' | 'onKeyDown' | 'className'> & {
  loadMentions: (query: string) => Promise<MentionData[]>;
};

export const FormMentionTextarea = ({
  containerClassName = 'form-textarea-container',
  containerTestId,
  name,
  label,
  tooltip,
  required,
  optional,
  placeholder,
  testId,
  renderBefore,
  renderAfter,
  maxLength,
  loadMentions,
  showError = true,
  enforceMaxLength = true,
  style,
  ...props
}: FormMentionTextareaProps) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField(name);

  function fetchUsers(query: string, callback: (mentions: SuggestionDataItem[]) => void) {
    if (!query) return;
    loadMentions(query)
      .then((mentions: MentionData[]) =>
        mentions.map<SuggestionDataItem>((mention) => ({ display: mention.name, id: mention.userId })),
      )
      .then(callback);
  }

  return (
    <FormInputContainer
      name={name}
      error={showError ? error : undefined}
      errorPosition={maxLength ? 'before' : 'after'}
      touched={touched}
      label={label}
      tooltip={tooltip}
      required={required}
      optional={optional}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      <fieldset className="!m-0 !p-0">
        <MentionsInput
          data-testid={testId || name}
          className="mentions"
          classNames={classNames}
          placeholder={placeholder}
          aria-label={label}
          maxLength={enforceMaxLength ? maxLength : undefined}
          {...props}
          style={style}
          id={name}
          name={name}
          value={value}
          onChange={(event, newValue: string) => {
            console.log('newValue', newValue);
            setValue(newValue);
          }}
          onBlur={onBlur}
          forceSuggestionsAboveCursor={true}
        >
          <Mention
            displayTransform={(login, name) => `@${name}`}
            trigger="@"
            data={fetchUsers}
            className={classNames.mentions__mention}
          />
        </MentionsInput>
      </fieldset>
      {maxLength && (
        <div className="max-characters">
          <span>{maxLength} characters max</span>
          <span className={`count ${maxLength && value?.length > maxLength ? 'error' : ''}`}>{value?.length || 0}</span>
        </div>
      )}
    </FormInputContainer>
  );
};
