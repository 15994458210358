import { MediaFolder } from '../types';
import React, { MouseEventHandler } from 'react';
import { Grid } from '../../core/layout/grid/grid';
import { MdOutlineFolder } from 'react-icons/md';
import { Icon, IconColor, IconPull, IconSize } from '../../core/icon/icon';

export interface MediaFolderGridProps {
  folders: MediaFolder[];
  onClickFolder: (folder: MediaFolder | undefined) => void;
}

export function MediaFolderGrid({ folders, onClickFolder }: MediaFolderGridProps) {
  if (!folders || !folders.length) {
    return null;
  }

  const onClick =
    (folder: MediaFolder): MouseEventHandler =>
    (e) => {
      e.preventDefault();

      onClickFolder(folder);
    };

  return (
    <Grid.Container fluid className="media-folders" data-testid="media-folders">
      <Grid.Row>
        {folders.map((folder) => (
          <Grid.Column
            key={folder._id}
            colspan={6}
            colspanSm={6}
            colspanMd={4}
            colspanLg={3}
            colspanXl={2}
            onClick={onClick(folder)}
          >
            <div className="media-folder" data-testid="media-folder">
              <Icon icon={MdOutlineFolder} size={IconSize.XLarge} color={IconColor.Gray} pull={IconPull.Center} />
              <a onClick={onClick(folder)} href="#">
                {folder.name}
              </a>
            </div>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid.Container>
  );
}
