import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useLoader } from '../../../hooks/useLoader';
import { GetEmailTemplatesResponse, MailContentFollowup, MailContentTemplate, UseEmailTemplatesResult } from '../types';

export type UseEmailTemplatesProps = {
  apiBaseUrl: string;
  employerId: string;
  emailType: string;
};

export const useEmailTemplates = ({
  apiBaseUrl,
  employerId,
  emailType,
}: UseEmailTemplatesProps): UseEmailTemplatesResult => {
  const { loaded, loading, error, cancelLoading, run } = useLoader({ cancelOnUnmount: true });

  const [templates, setTemplates] = useState<MailContentTemplate[]>([]);
  const [followups, setFollowups] = useState<MailContentFollowup[]>([]);

  const loadData = useCallback(
    async (type: string) => {
      setFollowups([]);

      cancelLoading();
      await run(
        axios.get<GetEmailTemplatesResponse>(`/api/employer/${employerId}/email-templates/${type}`, {
          baseURL: apiBaseUrl,
        }),
      )?.then((result) => {
        if (!result) {
          // Cancelled
          return;
        }

        if (result.data?.success) {
          setTemplates(result.data.templates);
          setFollowups(result.data.followup);
        } else {
          throw new Error(result.data?.error || 'Unknown error');
        }
      });
    },
    [apiBaseUrl, employerId, run, cancelLoading],
  );

  const reload = useCallback(() => {
    return loadData(emailType);
  }, [loadData, emailType]);

  useEffect(() => {
    loadData(emailType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailType]);

  return {
    error,
    loaded,
    loading,
    reload,
    templates,
    followups,
  };
};
