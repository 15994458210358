import { IconType } from 'react-icons';
import React, { SVGProps } from 'react';

export enum IconSize {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  XLarge = 'xl',
}

export enum IconColor {
  Primary = 'primary',
  Gray = 'gray',
  Light = 'light',
  Dark = 'dark',
  Remove = 'remove',
  Orange = 'orange',
  Blue = 'blue',
  Purple = 'purple',
}

export enum IconPull {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export type IconProps = {
  icon: IconType;
  size?: IconSize;
  color?: IconColor;
  pull?: IconPull;
  testId?: string;
} & SVGProps<unknown>;

export function Icon({ icon: Icon, size, color, pull, className, testId, onClick, ...props }: IconProps) {
  const classes = ['icon'];
  if (size) classes.push(`icon-${size}`);
  if (color) classes.push(`icon-${color}`);
  if (onClick) classes.push(`clickable`);
  if (pull) classes.push(`u-pull-${pull}`);
  if (className) classes.push(className);

  return (
    <span className={classes.join(' ')} data-testid={testId} onClick={onClick}>
      <Icon {...props} />
    </span>
  );
}
