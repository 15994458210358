import { Icon, IconPull, IconSize } from '../../icon/icon';
import { NavigateToStep } from './types';
import { StepNumber } from './step-number';
import { FaCheck, FaChevronRight } from 'react-icons/fa';
import classnames from 'classnames';

type StepProps = {
  label: string;
  navigateToStep: NavigateToStep | undefined;
  index: number;
  active: boolean;
  complete: boolean;
  testId?: string;
};

export const Step = ({ label, navigateToStep, index, active, complete, testId }: StepProps) => {
  return (
    <div
      className={classnames('stepper-step', {
        'is-active': active && !complete,
        'is-complete': complete,
        'is-clickable': !!navigateToStep,
      })}
      data-testid={testId || `step-${index}`}
      onClick={navigateToStep ? () => navigateToStep(index) : undefined}
    >
      <div className="stepper-indicator">
        <StepNumber number={index + 1} active={active} complete={complete} />
      </div>
      <h5 className="stepper-label">{label}</h5>

      <Icon
        icon={complete ? FaCheck : FaChevronRight}
        className={classnames('stepper-arrow', { complete, active: active && !complete })}
        size={IconSize.Medium}
        pull={IconPull.Right}
      />
    </div>
  );
};
