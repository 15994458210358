import { PropsWithChildren, ReactNode, useState } from 'react';
import { Button, ButtonProps } from '../button/button';
import { Modal, ModalProps } from './modal';

type BodyComponent = (close: () => void) => JSX.Element;

export type ModalButtonProps = Omit<ButtonProps, 'onClick'> & {
  body: BodyComponent | ReactNode;
  modalProps?: Partial<ModalProps>;
  onClose?: () => void;
};

export function ModalButton({
  body,
  modalProps = {},
  onClose: onCloseProp,
  ...props
}: PropsWithChildren<ModalButtonProps>) {
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
    onCloseProp?.();
  };

  return (
    <>
      <Button {...props} onClick={() => setOpen(true)} />
      {open ? (
        <Modal open={true} onClose={onClose} {...modalProps}>
          {typeof body === 'function' ? body(onClose) : body}
        </Modal>
      ) : null}
    </>
  );
}
