import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from 'react';
import { Step } from './step';
import { NavigateToStep, StepConfig, StepperVariant } from './types';
import { Button, ButtonSize, ButtonVariant } from '../../button/button';
import { FaTimes } from 'react-icons/fa';
import { Icon, IconColor } from '../../icon/icon';
import { Box } from '../box/box';
import { StepNumber } from './step-number';

export type StepperProps = {
  steps: StepConfig[];
  initialStep: number;
  variant?: StepperVariant;
  className?: string;
};

export type StepperRef = {
  navigateToStep: NavigateToStep;
};

export const Stepper = forwardRef<StepperRef, StepperProps>(
  ({ variant, steps, initialStep, className }: StepperProps, forwardedRef) => {
    const [mobileOpen, setMobileOpen] = React.useState(initialStep > 0);
    const [activeStepIndex, setActiveStepIndex] = React.useState(initialStep);
    const ContentComponent = useMemo(() => steps?.[activeStepIndex]?.content, [steps, activeStepIndex]);

    const navigateToStep: NavigateToStep = useCallback(
      (index) => {
        setMobileOpen(true);
        let destinationStepIndex: number = typeof index === 'number' ? index : 0;
        switch (index) {
          case 'next':
            destinationStepIndex = activeStepIndex + 1;
            break;
          case 'prev':
            destinationStepIndex = activeStepIndex - 1;
            break;
        }

        if (destinationStepIndex < 0 || destinationStepIndex >= steps.length) return;

        setActiveStepIndex(destinationStepIndex);

        setTimeout(() => {
          window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }, 50);
      },
      [activeStepIndex, steps.length, setActiveStepIndex],
    );

    useImperativeHandle(forwardedRef, () => ({
      navigateToStep,
    }));

    const wrapperClasses = ['stepper-wrapper'];
    if (variant) wrapperClasses.push(`${variant}-stepper-wrapper`);
    if (mobileOpen) wrapperClasses.push('open');
    if (className) wrapperClasses.push(className);

    const headClasses = ['stepper-head'];
    if (variant) headClasses.push(`${variant}-stepper-head`);

    const bodyClasses = ['stepper-body'];
    if (variant) bodyClasses.push(`${variant}-stepper-body`);
    if (mobileOpen) bodyClasses.push('open');

    return (
      <div className={wrapperClasses.join(' ')}>
        <div className="u-full-height" style={{ display: variant === StepperVariant.Vertical ? 'flex' : 'block' }}>
          <div className={headClasses.join(' ')}>
            {steps.map(({ label, canGoToStep, icon, content, complete, testId }, i) => (
              <Step
                key={i}
                testId={testId}
                index={i}
                active={i === activeStepIndex}
                navigateToStep={canGoToStep ? navigateToStep : undefined}
                complete={complete || false}
                label={label}
              />
            ))}
          </div>

          <div className={bodyClasses.join(' ')}>
            <Box className="stepper-body-head" p={4}>
              <StepNumber number={activeStepIndex + 1} active complete={steps[activeStepIndex].complete || false} />
              <h2 className="u-flex-1">{steps[activeStepIndex].label}</h2>

              <Button
                variant={ButtonVariant.Icon}
                onClick={() => setMobileOpen(false)}
                className="close"
                size={ButtonSize.Small}
              >
                <Icon icon={FaTimes} color={IconColor.Dark} />
              </Button>
            </Box>

            <div className="stepper-body-content">
              {ContentComponent ? <ContentComponent navigateToStep={navigateToStep} /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  },
);
