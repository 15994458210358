import React from 'react';
import { components } from 'react-select';
import { BiCaretDown } from 'react-icons/bi';

export const ReactSelectDropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <BiCaretDown />
    </components.DropdownIndicator>
  );
};
