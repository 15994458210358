import { FC, ReactNode } from 'react';

export enum StepperVariant {
  Default = 'default',
  Vertical = 'vertical',
  Inline = 'inline',
}

export enum StepColor {
  Complete = 'complete',
  Warning = 'warning',
  Error = 'error',
}

export type NavigateToStep = (step: number | 'next' | 'prev') => void;

export type StepContentProps = {
  onDone?: () => void;
  navigateToStep?: NavigateToStep;
};

export type StepConfig = {
  label: string;
  testId?: string;
  canGoToStep?: boolean;
  icon?: ReactNode;
  content: FC<StepContentProps>;
  complete?: boolean;
};
