import React from 'react';

export type DatagridSelectBoxProps = { checked?: boolean; onChecked: (checked: boolean) => void };

export const DatagridSelectBox = ({ checked, onChecked }: DatagridSelectBoxProps) => {
  return (
    <div
      className="w-6 h-6 pt-4 pb-5 px-5 mx-auto cursor-pointer rounded-full hover:shadow-light hover:shadow-blue-100"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();

        onChecked(!checked);
      }}
    >
      <input
        className="form-input datagrid-select-box mb-3 text-white checked:accent-white w-6 h-6"
        type="checkbox"
        onChange={(e) => onChecked(e.target.checked)}
        checked={checked}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
};
