import React, { InputHTMLAttributes } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';
import classnames from 'classnames';

export type FormPhoneInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'name' | 'value' | 'onChange' | 'onBlur'
> &
  GenericFormInputProps & {
    fullWidth?: boolean;
  };

export const FormPhoneInput = ({
  containerClassName,
  containerTestId,
  name,
  label,
  tooltip,
  required,
  placeholder,
  testId,
  renderBefore,
  renderAfter,
  tabIndex,
  fullWidth = true,
}: FormPhoneInputProps) => {
  const [focused, setFocused] = React.useState(false);
  const [{ onBlur }, { error, touched, initialValue }, { setValue, setTouched }] = useField(name);

  const onChange = (value: string, { dialCode: countryCode }: CountryData) => {
    const phoneNumber = value?.startsWith(countryCode) ? value.substring(countryCode.length) : value;
    setValue({ countryCode, phoneNumber });
  };

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      label={label}
      tooltip={tooltip}
      required={required}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      <PhoneInput
        inputProps={{
          'data-testid': testId,
          onFocus: () => setFocused(true),
          onBlur: (evt: InputEvent) => {
            onBlur(evt);
            setFocused(false);
            setTouched(true);
          },
          tabIndex,
        }}
        containerClass={classnames({ 'u-full-width': fullWidth, focused })}
        inputClass={classnames('form-input', 'form-phone-input', { 'u-full-width': fullWidth })}
        value={initialValue ? `${initialValue.countryCode}${initialValue.phoneNumber}` : undefined}
        onChange={onChange}
      />
    </FormInputContainer>
  );
};
