import classnames from 'classnames';
import React from 'react';
import { IconType } from 'react-icons';
import { Icon } from '../../icon/icon';

export type SubnavLinkProps = {
  className?: string;
  active?: boolean;
  path: string;
  label: string;
  icon: IconType;
  navigate?: (path: string) => void;
};

export const SubnavLink = ({ active, className, label, icon, navigate, path }: SubnavLinkProps) => {
  const isServer = typeof window === 'undefined';

  const onClickLink = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (navigate) {
      navigate(path);
    } else {
      window.location.href = path;
    }
  };

  return (
    <li
      className={classnames(className, {
        active: active || (!isServer && window.location.pathname === path),
      })}
      onClick={onClickLink}
    >
      <a onClick={onClickLink} href={path} data-testid="subnav-link">
        <Icon icon={icon} />
        <span className="label">{label}</span>
      </a>
    </li>
  );
};
