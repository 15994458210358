import React, { PropsWithChildren } from 'react';
import * as Accordion from '@radix-ui/react-accordion';

export type AccordionContentProps = PropsWithChildren;

export const AccordionContent = React.forwardRef<HTMLDivElement, AccordionContentProps>(
  ({ children }, forwardedRef) => (
    <Accordion.Content className="accordion-content" ref={forwardedRef}>
      <p className="font-light accordion-contenttext">{children}</p>
    </Accordion.Content>
  ),
);
