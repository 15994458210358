import { MdInfoOutline } from 'react-icons/md';
import { TextTooltip, TextTooltipProps } from './text-tooltip';
import { FC } from 'react';
import cx from 'classnames';

type Props = Omit<TextTooltipProps, 'children'> & {
  className?: string;
};

export const InfoTooltip: FC<Props> = ({ className, ...props }) => (
  <TextTooltip {...props}>
    <MdInfoOutline className={cx('text-[#426085] cursor-help text-24 mx-4', className)} />
  </TextTooltip>
);
