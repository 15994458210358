import React from 'react';
import { IconType } from 'react-icons';

export type GenericFormInputProps = {
  name: string;
  label?: string;
  labelClassName?: string;
  icon?: IconType;
  tooltip?: string;
  required?: boolean;
  optional?: boolean;
  testId?: string;
  placeholder?: string;
  containerClassName?: string;
  containerTestId?: string;
  renderBefore?: React.ReactNode;
  renderAfter?: React.ReactNode;
};
