import React from 'react';
import classnames from 'classnames';

export type HeaderLoaderProps = { loading: boolean; quickLoad: boolean };

export const HeaderLoader = ({ loading, quickLoad }: HeaderLoaderProps) => {
  return (
    <>
      <div className={classnames('loading-bar', { active: loading, quick: quickLoad })} />
      {loading ? <div className="overlay loading-overlay active" /> : null}
    </>
  );
};
