import classnames from 'classnames';
import React from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { SubnavLink, SubnavLinkProps } from './subnav-link';
import { Icon } from '../../icon/icon';

export type SubnavProps = {
  mobileOpen?: boolean;
  className?: string;
  basePath?: string;
  links: Array<Omit<SubnavLinkProps, 'navigate'>>;
  navigate?: (path: string) => void;
  footer?: React.ReactNode;
  onClickLogout?: () => Promise<void> | void;
};

export const Subnav = ({ basePath, className, mobileOpen, links, navigate, onClickLogout }: SubnavProps) => {
  return (
    <ul className={classnames('subnav', className, { 'mobile-subnav-open': mobileOpen })}>
      {links.map((link) => (
        <SubnavLink
          key={link.path}
          {...link}
          path={basePath ? `${basePath}${link.path}` : link.path}
          navigate={navigate}
        />
      ))}

      {onClickLogout ? (
        <li
          onClick={() => {
            onClickLogout();
          }}
          className="logout u-mt-4 hidden-sm-up"
        >
          <a data-testid="candidate-portal-section-link">
            <Icon icon={FaSignOutAlt} />
            <span className="label">Logout</span>
          </a>
        </li>
      ) : null}
    </ul>
  );
};
