import { forwardRef, MouseEvent, PropsWithChildren, useCallback, useMemo, useImperativeHandle } from 'react';
import { Accept, DropzoneRootProps, useDropzone } from 'react-dropzone';
import { useLoader } from '../../../../hooks/useLoader';

export type DropzoneUploadProps = {
  onFileSelected: (file: File) => Promise<void>;
  accept: Accept;
  maxSizeMb?: number;
  className?: string;
  loadingText?: string;
  showStatusText?: boolean;
  noClick?: boolean;
  dropzoneActiveText?: string;
  invalidFileText?: string;
  disabled?: boolean;
};

export type DropzoneUploadRef = {
  open: () => void;
};

export const DropzoneUpload = forwardRef<DropzoneUploadRef, PropsWithChildren<DropzoneUploadProps>>(
  (
    {
      onFileSelected,
      accept,
      maxSizeMb = 2,
      className: classNameProp,
      children,
      showStatusText = true,
      noClick = false,
      loadingText = 'Uploading file...',
      invalidFileText = 'Invalid file',
      dropzoneActiveText = 'Drop files here to upload',
      disabled,
    },
    ref,
  ) => {
    const { run, loading } = useLoader({ cancelOnUnmount: false });

    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        const file = acceptedFiles?.[0];
        if (!file) {
          return;
        }

        run(onFileSelected(file));
      },
      [run, onFileSelected],
    );

    const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject, open } = useDropzone({
      onDropAccepted: onDrop,
      accept,
      maxFiles: 1,
      maxSize: maxSizeMb * 1024 * 1024,
      noClick,
      noKeyboard: true,
      disabled,
    });

    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [],
    );

    const [containerClassName, overlayClassname] = useMemo(() => {
      const containerClasses = ['container', 'fluid', 'dropzone-upload'];
      const overlayClasses = ['overlay'];
      const commonClasses = [];

      if (loading || isDragActive || isDragAccept || isDragReject) overlayClasses.push('is-active');
      if (isDragActive) containerClasses.push('is-active');
      if (isDragAccept) commonClasses.push('is-accepted');
      if (isDragReject) commonClasses.push('is-rejected');
      if (isFocused) commonClasses.push('is-focused');
      if (disabled) commonClasses.push('is-disabled');

      if (classNameProp) containerClasses.push(classNameProp);

      return [[...containerClasses, ...commonClasses].join(' '), [...overlayClasses, ...commonClasses].join(' ')];
    }, [loading, isDragActive, isDragAccept, isDragReject, isFocused, disabled, classNameProp]);

    const rootProps = getRootProps({ className: containerClassName }) as { className: string } & DropzoneRootProps;

    return (
      <div
        {...rootProps}
        onClick={(e: MouseEvent<HTMLDivElement>) =>
          (e.target as HTMLDivElement).closest('.dropzone-upload') && rootProps.onClick?.(e)
        }
        data-testid="dropzone-upload"
      >
        {children}
        {showStatusText ? (
          <div className={overlayClassname}>
            <p>{loading ? loadingText : isDragReject ? invalidFileText : dropzoneActiveText}</p>
          </div>
        ) : null}
        <input {...getInputProps()} />
      </div>
    );
  },
);
