import * as RadixMenu from '@radix-ui/react-dropdown-menu';
import classnames from 'classnames';
import { FC } from 'react';

type ItemProps = {
  children: React.ReactNode;
  styled?: boolean;
  className?: string;
  [key: string]: unknown;
};

export const DropdownMenuItem: FC<ItemProps> = ({ children, className, styled = true, ...props }: ItemProps) => {
  return (
    <RadixMenu.Item className={classnames({ 'dropdown-item': styled }, className)} {...props}>
      {children}
    </RadixMenu.Item>
  );
};
