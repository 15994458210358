import { EmailTemplatesBackendAdapter } from '../types';
import { useEmailTemplateDynamicData } from './useEmailTemplateDynamicData';
import { useEmailTemplates } from './useEmailTemplates';
import { useUpdateEmailTemplate } from './useUpdateEmailTemplate';
import { useBuildEmailTemplatePreview } from './useBuildEmailTemplatePreview';
import { useDeleteEmailTemplate } from './useDeleteEmailTemplate';

export const defaultEmailTemplateBackendAdapter = ({
  apiBaseUrl,
  employerId,
}: {
  apiBaseUrl: string;
  employerId: string;
}): EmailTemplatesBackendAdapter => {
  return {
    useEmailTemplates: ({ emailType }) => useEmailTemplates({ apiBaseUrl, employerId, emailType }),
    useUpdateEmailTemplate: ({ emailType, onTemplateUpdated }) =>
      useUpdateEmailTemplate({
        apiBaseUrl,
        employerId,
        emailType,
        onTemplateUpdated,
      }),
    useBuildEmailTemplatePreview: ({ emailType }) =>
      useBuildEmailTemplatePreview({
        apiBaseUrl,
        employerId,
        emailType,
      }),
    useDeleteEmailTemplate: ({ emailType, onTemplateDeleted }) =>
      useDeleteEmailTemplate({
        apiBaseUrl,
        employerId,
        emailType,
        onTemplateDeleted,
      }),
    useEmailTemplateDynamicData: ({ emailType }) => useEmailTemplateDynamicData({ apiBaseUrl, employerId, emailType }),
  };
};
