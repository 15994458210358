import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { GetMediaFoldersResponse, MediaFolder, UseFolderTraverseResult } from '../types';
import { useLoader } from '../../../hooks/useLoader';

export type UseFolderTraverseProps = {
  apiBaseUrl: string;
  employerId: string;
};

export const useFolderTraverse = ({ apiBaseUrl, employerId }: UseFolderTraverseProps): UseFolderTraverseResult => {
  const { loaded, loading, error, cancelLoading, run } = useLoader({ cancelOnUnmount: true });

  const [parentFolders, setParentFolders] = useState<MediaFolder[]>([]);
  const [currentFolder, setCurrentFolder] = useState<MediaFolder>();
  const [subFolders, setSubFolders] = useState<MediaFolder[]>();

  const loadFolderData = useCallback(
    (folderId?: string | null) => {
      cancelLoading();
      run(
        axios.get<GetMediaFoldersResponse>(
          `/api/employer/${employerId}/media-folders${folderId ? `/${folderId}` : ''}`,
          {
            baseURL: apiBaseUrl,
          },
        ),
      )?.then((result) => {
        if (!result) {
          // Cancelled
          return;
        }

        if (result.data?.success) {
          setSubFolders(result.data.folders);
        } else {
          throw new Error(result.data?.error || 'Unknown error');
        }
      });
    },
    [apiBaseUrl, employerId, run, cancelLoading],
  );

  const selectFolder = useCallback(
    (newFolder: MediaFolder | undefined) => {
      // Set parent folders by pushing new folder or removing previous folder
      if (!newFolder) {
        setParentFolders([]);
      } else if (currentFolder?._id === newFolder?.parentId) {
        setParentFolders([...parentFolders, currentFolder]);
      } else if (currentFolder) {
        setParentFolders(parentFolders.filter((parentFolder) => parentFolder._id !== newFolder._id));
      }

      setCurrentFolder(newFolder);
      loadFolderData(newFolder?._id);
    },

    [currentFolder, parentFolders, loadFolderData],
  );

  const reload = useCallback(() => {
    loadFolderData(currentFolder?._id);
  }, [loadFolderData, currentFolder?._id]);

  useEffect(() => {
    selectFolder(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
    loaded,
    loading,
    currentFolder,
    selectFolder,
    reload,
    subFolders,
    parentFolders,
  };
};
