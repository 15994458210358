import { useCallback, useEffect, useRef, useState } from 'react';

export type UseLoaderOpts = {
  cancelOnUnmount?: boolean;
};

const defaultOpts: UseLoaderOpts = {
  cancelOnUnmount: true,
};

/**
 * This hook is designed to make running async functions easier, e.g. loading data from the api. It provides:
 * - Exposes loading, loaded and error values
 * - Provides `run` function to run async functions
 * - Provides `cancelLoading` function to cancel the current running async function
 * - Auto cancels any running async function when the hook is unmounted by resolving the current run promise with null value
 *
 * Use it like this:
 * ```ts
 *   const { loaded, loading, error, cancelLoading, run } = useLoader();
 *
 *   ...
 *   run(axios.get('/api/employer/123/media-folders')).then((data) => {
 *   if (!data) {
 *     // cancelled
 *     return;
 *   }
 *
 *   setData(data);
 * ```
 */
export const useLoader = ({ cancelOnUnmount }: UseLoaderOpts = defaultOpts) => {
  const [error, setError] = useState<string>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const cancelled = useRef({ value: false });

  // This function is used to return null and break the promise chain if the loader has been cancelled
  const afterRun = useCallback(
    <T>(data: T): T | null => {
      if (cancelled.current.value) {
        throw new Error('Cancelled');
      }

      return data;
    },
    [cancelled],
  );

  const run = useCallback(
    async <T>(runnable: Promise<T>): Promise<T | null> => {
      cancelled.current.value = false;
      setError(undefined);
      setLoading(true);

      return runnable
        ?.then(afterRun)
        .catch((e: Error) => {
          setError(e?.message || e?.toString() || 'Unknown Error');
          return null;
        })
        .finally(() => {
          if (!cancelled.current.value) {
            setLoading(false);
            setLoaded(true);
          }

          cancelled.current.value = false;
        });
    },
    [afterRun, cancelled],
  );

  const cancelLoading = useCallback(() => {
    cancelled.current.value = true;
    setError(undefined);
    setLoaded(false);
    setLoading(false);
  }, []);

  useEffect(() => {
    const val = cancelled.current;
    return () => {
      if (cancelOnUnmount) {
        val.value = true;
      }
    };
  }, [cancelOnUnmount]);

  return {
    error,
    setError,
    loaded,
    loading,
    run,
    cancelLoading,
  };
};
