import { useCallback } from 'react';
import axios from 'axios';
import { useLoader } from '../../../hooks/useLoader';
import { BuildEmailTemplatePreviewResponse, UseBuildEmailTemplatePreviewResult } from '../types';

export type UseBuildEmailTemplatePreviewOpts = {
  apiBaseUrl: string;
  employerId: string;
  emailType: string;
};

export const useBuildEmailTemplatePreview = ({
  apiBaseUrl,
  employerId,
  emailType,
}: UseBuildEmailTemplatePreviewOpts): UseBuildEmailTemplatePreviewResult => {
  const { loading, error, run, setError } = useLoader({ cancelOnUnmount: false });

  const buildPreview = useCallback(
    (template: string, type: 'body' | 'subject' = 'body'): Promise<string | null> => {
      return run(
        axios
          .post<BuildEmailTemplatePreviewResponse>(
            `/api/employer/${employerId}/email-templates/${emailType}/preview`,
            { template, type },
            {
              baseURL: apiBaseUrl,
            },
          )
          .then((result) => {
            if (!result?.data?.success) {
              setError('Error loading preview from API');
              // Cancelled
              return null;
            }

            return result.data.preview;
          })
          .catch((e) => {
            setError(e?.message || e?.toString() || 'Unknown Error');
            return null;
          }),
      );
    },
    [apiBaseUrl, employerId, emailType, run, setError],
  );

  return {
    error,
    loading,
    buildPreview,
  };
};
