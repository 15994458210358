import { ApiResponse } from '../../../types/core';

export type Locale = 'de-DE' | 'en-US' | 'en-GB' | 'es-ES' | 'es-419' | 'fr-CA' | 'it-IT';

// Locale label mappings
export const LOCALE_LABELS: Record<Locale, string> = {
  'en-US': 'English (US)',
  'en-GB': 'English (UK)',
  'de-DE': 'Deutsch',
  'es-ES': 'Español',
  'es-419': 'Español (LatAm)',
  'fr-CA': 'Français (Canada)',
  'it-IT': 'Italiano',
};

export interface MailContentTemplate {
  locale: Locale;
  body: string; // The EJS template, previously stored in File System
  subject: string; // Previously stored in the core MailContent object
}

export interface MailContentFollowup {
  delayInDays: number;
  type: string;
  recurring?: boolean;
}

export interface DynamicDataTemplate {
  key: string;
  type: 'value' | 'html';
  ejs: string;
  label: string;
}

export type UpdateEmailTemplateRequest = MailContentTemplate;

export type UpdateEmailTemplateResponse = ApiResponse<{ templates: MailContentTemplate[] }>;

export type BuildEmailTemplatePreviewResponse = ApiResponse<{ preview: string }>;

export type GetEmailTemplatesResponse = ApiResponse<{
  templates: MailContentTemplate[];
  followup: MailContentFollowup[];
}>;

export type GetEmailTemplateDynamicDataResponse = ApiResponse<{ templates: DynamicDataTemplate[] }>;

export type UseBuildEmailTemplatePreviewResult = {
  error: string | undefined;
  loading: boolean;
  buildPreview: (template: string, type: 'body' | 'subject') => Promise<string | null>;
};

export type UseDeleteEmailTemplateResult = {
  error: string | undefined;
  loading: boolean;
  deleteEmailTemplate: (template: MailContentTemplate) => void;
};

export type UseEmailTemplateDynamicDataResult = {
  error: string | undefined;
  loaded: boolean;
  loading: boolean;
  reload: () => Promise<void>;
  templates: DynamicDataTemplate[];
};

export type UseEmailTemplatesResult = {
  error: string | undefined;
  loaded: boolean;
  loading: boolean;
  reload: () => Promise<void>;
  templates: MailContentTemplate[];
  followups: MailContentFollowup[];
};

export type UseUpdateEmailTemplateResult = {
  error: string | undefined;
  loading: boolean;
  updateEmailTemplate: (template: MailContentTemplate) => void;
};

export type EmailTemplatesBackendAdapter = {
  useBuildEmailTemplatePreview: (opts: { emailType: string }) => UseBuildEmailTemplatePreviewResult;
  useEmailTemplateDynamicData: (opts: { emailType: string }) => UseEmailTemplateDynamicDataResult;
  useUpdateEmailTemplate: (opts: {
    emailType: string;
    onTemplateUpdated: (template: MailContentTemplate) => void;
  }) => UseUpdateEmailTemplateResult;
  useEmailTemplates: (opts: { emailType: string }) => UseEmailTemplatesResult;
  useDeleteEmailTemplate: (opts: {
    emailType: string;
    onTemplateDeleted?: (template: MailContentTemplate) => void;
  }) => UseDeleteEmailTemplateResult;
};
