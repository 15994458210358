import React, { InputHTMLAttributes, useCallback } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';
import * as Checkbox from '@radix-ui/react-checkbox';
import { MdCheck } from 'react-icons/md';

export type FormCheckboxInputInputProps = Omit<
  InputHTMLAttributes<HTMLElement>,
  'name' | 'value' | 'onChange' | 'onBlur'
> &
  Omit<GenericFormInputProps, 'label'> & {
    onChange?: (value: boolean) => void;
    label?: string | React.ReactNode;
    description?: string | React.ReactNode;
  };

export const FormCheckboxInput = ({
  containerClassName,
  containerTestId,
  name,
  label,
  description,
  tooltip,
  required,
  testId,
  onChange,
  renderAfter,
  renderBefore,
}: FormCheckboxInputInputProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<boolean | null | undefined>(name);

  const onClick = useCallback(() => {
    setValue(!value);
    onChange && onChange(!value);

    setTimeout(() => {
      setTouched(true);
    }, 10);
  }, [onChange, setTouched, setValue, value]);

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      tooltip={tooltip}
      required={required}
      testId={testId}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      <fieldset id={name} className="checkbox flex items-center">
        <Checkbox.Root
          id={name}
          name={name}
          aria-label={label?.toString()}
          className="appearance-none rounded-[4px] outline-none"
          value={1}
          onClick={onClick}
          checked={!!value}
        >
          <Checkbox.Indicator className="text-blue-main">
            <MdCheck className="pt-[2px]" />
          </Checkbox.Indicator>
        </Checkbox.Root>
        <label className="pl-[10px] text-[15px] leading-none" htmlFor={name} onClick={onClick}>
          {label}
        </label>
      </fieldset>
      {description ? <p className="text-14 leading-tight text-gray-200 mb-0 mt-2">{description}</p> : null}
    </FormInputContainer>
  );
};
