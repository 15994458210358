import React, { useCallback } from 'react';
import { useField } from 'formik';
import { FormInputContainer } from '../form-input-container';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { GroupBase, OnChangeValue } from 'react-select';
import uniqBy from 'lodash/uniqBy';
import { FormAsyncSelectInputProps, OptionTypeBase } from './form-async-select-input';
import { ReactSelectDropdownIndicator } from './react-select-dropdown-indicator';
import { ReactSelectClearIndicator } from './react-select-clear-indicator';

export type FormSelectCreateInputProps<OptionType extends OptionTypeBase> = FormAsyncSelectInputProps<
  OptionType,
  false
> & {
  createOption: (value: string) => Promise<OptionType>;
};

export const FormSelectCreateInput = <OptionType extends OptionTypeBase>({
  loadOptions: loadOptionsBase,
  containerClassName,
  containerTestId,
  name,
  label,
  tooltip,
  required,
  placeholder,
  testId,
  renderAfter,
  renderBefore,
  createOption,
  menuPortalTarget,
  optional,
}: FormSelectCreateInputProps<OptionType>) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField<string | null>(name);
  const [loadedOptions, setLoadedOptions] = React.useState<OptionType[]>(
    value
      ? [
          {
            value: value,
            label: value?.toString(),
          } as OptionType,
        ]
      : [],
  );

  const loadOptions = useCallback(
    async (search: string) => {
      const response = await loadOptionsBase(search, loadedOptions.length);

      if (response.options.length > 0) {
        setLoadedOptions(uniqBy([...response.options, ...(loadedOptions as OptionType[])], 'value'));
      }

      return response.options;
    },
    [loadOptionsBase, loadedOptions],
  );

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      label={label}
      tooltip={tooltip}
      required={required}
      optional={optional}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      <fieldset>
        <AsyncCreatableSelect<OptionType, false, GroupBase<OptionType>>
          id={name}
          name={name}
          classNames={{
            container: () => 'react-select',
            control: () => 'react-select-control',
            option: () => 'react-select-option',
            placeholder: () => 'react-select-placeholder',
            valueContainer: () => 'react-select-value-container',
            input: () => 'react-select-input',
            menu: () => 'react-select-menu',
            indicatorSeparator: () => 'react-select-indicator-separator',
            indicatorsContainer: () => 'react-select-indicators-container',
            dropdownIndicator: () => 'react-select-dropdown-indicator',
            multiValue: () => 'pill pill-primary pill-small',
          }}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          components={{
            // @ts-ignore
            DropdownIndicator: ReactSelectDropdownIndicator,
            // @ts-ignore
            ClearIndicator: ReactSelectClearIndicator,
          }}
          placeholder={placeholder}
          aria-label={label}
          data-testid={testId || name}
          value={value ? loadedOptions.find((option) => option.value === value) : null}
          defaultOptions={true}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          loadOptions={loadOptions}
          closeMenuOnSelect={true}
          allowCreateWhileLoading={false}
          createOptionPosition="last"
          cacheOptions={false}
          isClearable={optional || !required}
          menuPortalTarget={menuPortalTarget}
          onCreateOption={async (value) => {
            const option = await createOption(value);

            setLoadedOptions([option, ...loadedOptions]);
            setValue(option.value);
          }}
          onChange={(val: OnChangeValue<OptionType, false>, e) => {
            console.log('val', val);
            console.log('e', e);
            setValue(val?.value || null);
          }}
          onBlur={onBlur}
        />
      </fieldset>
    </FormInputContainer>
  );
};
