import React, { useCallback, useState } from 'react';
import { Button, ButtonColor, ButtonProps } from './button';
import { Modal, ModalProps } from '../modal/modal';

export type ButtonConfirmation = {
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  confirm: string | React.ReactNode;
  cancel: string | React.ReactNode;
};

export type ConfirmationButtonProps = ButtonProps & {
  confirmation: ButtonConfirmation;
  confirmButtonProps?: Partial<Omit<ButtonProps, 'onClick'>>;
  modalProps?: Partial<ModalProps>;
};

export function ConfirmationButton({
  confirmation: { title, message, confirm, cancel },
  confirmButtonProps = {},
  onClick: onClickProp,
  modalProps = {},
  ...props
}: ConfirmationButtonProps) {
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const onClick = useCallback(() => {
    if (!confirmationOpen) {
      setConfirmationOpen(true);
    }
  }, [confirmationOpen]);

  const onClickConfirm = async () => {
    if (onClickProp) {
      const result = onClickProp();
      if (result instanceof Promise) {
        await result;
      }
    }

    setConfirmationOpen(false);
  };

  return (
    <>
      <Button {...props} onClick={onClick} />
      {confirmationOpen ? (
        <Modal onClose={() => setConfirmationOpen(false)} open className="confirmation-modal" {...modalProps}>
          <h4>{title}</h4>
          <p>{message}</p>
          <div className="u-pull-right">
            <Button
              color={ButtonColor.Cancel}
              onClick={() => setConfirmationOpen(false)}
              className="u-mr-2"
              children={cancel}
              title={typeof cancel === 'string' ? cancel : undefined}
              data-testid="cancel-button"
            />
            <Button
              color={ButtonColor.Primary}
              onClick={onClickConfirm}
              children={confirm}
              title={typeof confirm === 'string' ? confirm : undefined}
              {...confirmButtonProps}
              data-testid="confirm-button"
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
}
