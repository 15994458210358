import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { GetMediaItemsResponse, MediaItemResponse, UseMediaItemSearchResult } from '../types';
import { useLoader } from '../../../hooks/useLoader';

export type UseMediaItemSearchProps = {
  apiBaseUrl: string;
  employerId: string;
  folderId: string | null;
};

export const useMediaItemSearch = ({
  apiBaseUrl,
  employerId,
  folderId,
}: UseMediaItemSearchProps): UseMediaItemSearchResult => {
  const { loaded, loading, error, cancelLoading, run } = useLoader({ cancelOnUnmount: true });

  const [mediaItems, setMediaItems] = useState<MediaItemResponse[]>([]);

  const loadData = useCallback(
    (folderId?: string | null) => {
      cancelLoading();
      run(
        axios.get<GetMediaItemsResponse>(
          `/api/employer/${employerId}/media-folders${folderId ? `/${folderId}` : '/root'}/media`,
          {
            baseURL: apiBaseUrl,
          },
        ),
      )?.then((result) => {
        if (!result) {
          // Cancelled
          return;
        }

        if (result.data?.success) {
          setMediaItems(result.data.items);
        } else {
          throw new Error(result.data?.error || 'Unknown error');
        }
      });
    },
    [apiBaseUrl, employerId, run, cancelLoading],
  );

  const reload = useCallback(() => {
    loadData(folderId);
  }, [loadData, folderId]);

  useEffect(() => {
    loadData(folderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);

  return {
    error,
    loaded,
    loading,
    reload,
    mediaItems,
  };
};
