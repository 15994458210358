import React, { InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';
import { Icon } from '../icon/icon';
import classnames from 'classnames';

export type FormInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'name' | 'value' | 'onChange' | 'onBlur'> &
  GenericFormInputProps & {
    onChange?: (value: string) => void;
    fullWidth?: boolean;
  };

export const FormInput = ({
  containerClassName,
  containerTestId,
  name,
  label,
  labelClassName,
  icon,
  tooltip,
  required,
  optional,
  placeholder,
  testId,
  renderBefore,
  renderAfter,
  fullWidth = true,
  onChange: onChangeProp,
  type = 'text',
  ...props
}: FormInputProps) => {
  const [{ value, onChange, onBlur }, { error, touched }] = useField(name);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    onChangeProp && onChangeProp(e.target.value);
  };

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      label={label}
      labelClassName={labelClassName}
      icon={icon}
      tooltip={tooltip}
      required={required}
      optional={optional}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
      htmlFor={name}
    >
      <fieldset className={classnames({ 'with-icon': !!icon })}>
        {icon ? <Icon icon={icon} className="form-input-icon" /> : null}
        <input
          type={type}
          data-testid={testId || name}
          className={classnames('form-input', {
            'u-full-width': fullWidth,
          })}
          placeholder={placeholder}
          aria-label={label}
          {...props}
          id={name}
          name={name}
          value={value || ''}
          onChange={onChangeHandler}
          onBlur={onBlur}
        />
      </fieldset>
    </FormInputContainer>
  );
};
