import React, { MouseEventHandler } from 'react';
import { DatagridColumn, DatagridObject, DatagridTableHeadProps, SortDirection, SortState } from './types';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { IconType } from 'react-icons';
import { Icon, IconPull, IconSize } from '../icon/icon';

export const DatagridTableHead = <Data extends DatagridObject, Sort>(props: DatagridTableHeadProps<Data, Sort>) => {
  const onClickColumnHeader =
    (column: DatagridColumn<Data, Sort>): MouseEventHandler =>
    (evt) => {
      evt.preventDefault();
      if (props.sortable && column.sortable) {
        const newSortState: SortState<Sort> = {
          field: column.sortKey,
          direction:
            props.sortState?.field === column.sortKey
              ? props.sortState.direction === SortDirection.Asc
                ? SortDirection.Desc
                : SortDirection.Asc
              : SortDirection.Asc,
        };

        props.onSortChange(newSortState);
      }
    };

  return (
    <thead>
      <tr>
        {props.columns.map((column, i) => {
          const sortIcon: IconType | null =
            column.sortable && props.sortable
              ? column.sortKey === props.sortState?.field
                ? props.sortState?.direction === SortDirection.Asc
                  ? FaSortDown
                  : FaSortUp
                : FaSort
              : null;

          return (
            <th
              key={i}
              onClick={props.sortable && column.sortable ? onClickColumnHeader(column) : undefined}
              className={column.sortable ? 'sortable' : undefined}
              style={{ width: column.width }}
            >
              {column.header}
              {sortIcon ? <Icon icon={sortIcon} size={IconSize.Medium} pull={IconPull.Right} /> : null}
            </th>
          );
        })}

        {props.expandable ? <th></th> : null}
      </tr>
    </thead>
  );
};
