import React from 'react';
import { DynamicDataTemplate } from '../types';
import { Button, ButtonColor, ButtonSize, ButtonVariant, Icon } from '../../core';
import { FaPlus } from 'react-icons/fa';

type DynamicDataPickerProps = {
  templates: DynamicDataTemplate[];
  onTemplatePicked: (template: DynamicDataTemplate) => void;
};

export const DynamicDataPicker = ({ templates, onTemplatePicked }: DynamicDataPickerProps) => {
  const onClick = (template: DynamicDataTemplate) => () => {
    onTemplatePicked(template);
  };

  return (
    <table className="data-templates u-full-width" data-testid="data-template-picker">
      <thead>
        <tr>
          <th>Description</th>
          <th>Template</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {templates.map((template) => (
          <tr key={template.key}>
            <td>{template.label}</td>
            <td>{`{{ ${template.key} }}`}</td>
            <td>
              <Button
                variant={ButtonVariant.Icon}
                color={ButtonColor.Primary}
                size={ButtonSize.Small}
                onClick={onClick(template)}
              >
                <Icon icon={FaPlus} />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
