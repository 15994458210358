import React from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';
import { Box } from '../layout/box/box';
import classnames from 'classnames';

export type SwitchProps = {
  id: string;
  className?: string;
  label?: string;
  title?: string;
  subLabel?: string;
  icon?: React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
};

export const Switch = ({ icon, id, className, disabled, label, title, subLabel, onChange, checked }: SwitchProps) => {
  return (
    <Box className={classnames('switch-container', className)}>
      <RadixSwitch.Root
        className="switch-root"
        id={id}
        name={id}
        checked={checked}
        onCheckedChange={!disabled ? onChange : undefined}
        title={title}
        disabled={disabled}
        data-testid={`switch-${id}`}
      >
        <RadixSwitch.Thumb className="switch-thumb" />
      </RadixSwitch.Root>
      {label || subLabel ? (
        <Box display="flex" flexDirection="column" className="switch-info">
          {label ? (
            <label className="label text-offblack" htmlFor={id}>
              {label}
            </label>
          ) : null}
          {subLabel ? (
            <label className="sublabel text-offblack" htmlFor={id}>
              {subLabel}
            </label>
          ) : null}
        </Box>
      ) : null}

      {icon ? <Box className="switch-icon">{icon}</Box> : null}
    </Box>
  );
};
