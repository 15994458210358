import React, { useCallback } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from '../types';
import { FormInputContainer } from '../form-input-container';
import { AsyncPaginate } from 'react-select-async-paginate';
import { GroupBase, OnChangeValue } from 'react-select';
import uniqBy from 'lodash/uniqBy';
import { ReactSelectDropdownIndicator } from './react-select-dropdown-indicator';
import { ReactSelectClearIndicator } from './react-select-clear-indicator';
import classnames from 'classnames';

export type OptionTypeBase = {
  value: string;
  label: string;
};

export type FormAsyncSelectInputProps<
  OptionType extends OptionTypeBase,
  Multi extends boolean,
> = GenericFormInputProps & {
  className?: string;
  multi: Multi;
  isClearable?: boolean;
  defaultOptions?: boolean;
  loadOptions: (
    search: string | undefined,
    loadedOptionsCount: number,
  ) => Promise<{ total: number; options: OptionType[] }>;
  menuPortalTarget?: HTMLElement;
  onChange?: (value: Multi extends true ? string[] : string) => void;
};

export const FormAsyncSelectInput = <OptionType extends OptionTypeBase, Multi extends boolean>({
  loadOptions: loadOptionsBase,
  containerClassName,
  containerTestId,
  className,
  isClearable,
  name,
  label,
  labelClassName,
  tooltip,
  required,
  placeholder,
  testId,
  renderAfter,
  renderBefore,
  multi,
  optional,
  defaultOptions = true,
  onChange,
  menuPortalTarget,
}: FormAsyncSelectInputProps<OptionType, Multi>) => {
  type ValueType = Multi extends true ? string[] : string;
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField<ValueType | null>(name);
  const [loadedOptions, setLoadedOptions] = React.useState<OptionType[]>(
    value
      ? typeof value === 'string'
        ? [{ value: value, label: value?.toString() } as OptionType]
        : value?.map<OptionType>(
            (v) =>
              ({
                value: v,
                label: v?.toString(),
              } as OptionType),
          )
      : [],
  );

  const currentOptions = React.useMemo<OptionType[]>(() => {
    if (!value) {
      return [];
    }

    const result: Array<OptionType> =
      loadedOptions?.filter((option: OptionType): boolean =>
        multi ? value.includes(option.value) : value === option.value,
      ) || null;

    return result;
  }, [multi, value, loadedOptions]);

  const loadOptions = useCallback(
    async (search: string) => {
      const response = await loadOptionsBase(search, loadedOptions.length);

      if (response.options.length > 0) {
        setLoadedOptions(uniqBy([...response.options, ...(loadedOptions as OptionType[])], 'value'));
      }

      return {
        options: response.options,
        hasMore: response.total > loadedOptions.length + response.options.length,
      };
    },
    [loadOptionsBase, loadedOptions],
  );

  const onChangeMulti = (selectedOptions: OnChangeValue<OptionType, true>) => {
    const newVal = selectedOptions?.map((option) => option.value) || null;
    setValue(newVal as ValueType);
    onChange?.(newVal as ValueType);
  };

  const onChangeSingle = (selectedOption: OnChangeValue<OptionType, false>) => {
    const newVal = selectedOption?.value || null;
    setValue(newVal as ValueType);
    onChange?.(newVal as ValueType);
  };

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      label={label}
      labelClassName={labelClassName}
      tooltip={tooltip}
      required={required}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
      optional={optional}
    >
      <fieldset>
        <AsyncPaginate<OptionType, GroupBase<OptionType>, unknown, false>
          id={name}
          name={name}
          classNames={{
            container: () => classnames('react-select', className),
            menuPortal: () => 'react-select-menu-portal',
            control: () => 'react-select-control',
            option: () => 'react-select-option',
            placeholder: () => 'react-select-placeholder',
            valueContainer: () => 'react-select-value-container',
            multiValueLabel: () => 'react-select-value-label',
            input: () => 'react-select-input',
            menu: () => 'react-select-menu',
            indicatorSeparator: () => 'react-select-indicator-separator',
            indicatorsContainer: () => 'react-select-indicators-container',
            dropdownIndicator: () => 'react-select-dropdown-indicator',
            multiValue: () => 'pill pill-primary pill-small',
          }}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          components={{
            // @ts-ignore
            DropdownIndicator: ReactSelectDropdownIndicator,
            // @ts-ignore
            ClearIndicator: ReactSelectClearIndicator,
          }}
          placeholder={placeholder}
          aria-label={label}
          data-testid={testId || name}
          value={currentOptions}
          getOptionValue={(option) => option.value}
          getOptionLabel={(option) => option.label}
          loadOptions={loadOptions}
          isClearable={isClearable !== undefined ? isClearable : optional || !required}
          closeMenuOnSelect={!multi}
          defaultOptions={defaultOptions}
          debounceTimeout={500}
          // @ts-ignore
          isMulti={multi}
          // @ts-ignore
          onChange={multi ? onChangeMulti : onChangeSingle}
          onBlur={onBlur}
          menuPortalTarget={menuPortalTarget}
        />
      </fieldset>
    </FormInputContainer>
  );
};
