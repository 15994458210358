import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import BaseDatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import { Box } from '../layout/box/box';
import { getLocale } from './locales';
import { Button, ButtonColor, ButtonSize, ButtonVariant } from '../button/button';
import { Icon } from '../icon/icon';
import { FaTimesCircle } from 'react-icons/fa';
import classnames from 'classnames';

export type DatePickerProps = {
  type?: 'date' | 'datetime';
  id?: string;
  name?: string;
  onClose?: () => void;
  autoFocus?: boolean;
  className?: string;
  containerClassName?: string;
  selected?: Date;
  disabled?: boolean;
  readonly?: boolean;
  placeholderText?: string;
  isClearable?: boolean;
  onChange: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  selectStart?: boolean;
  endDate?: Date;
  selectEnd?: boolean;
  monthOnly?: boolean;
  yearOnly?: boolean;
  position?: 'top' | 'bottom';
  portal?: boolean;
  portalTarget?: HTMLElement;
};

export const DatePicker = ({
  className,
  containerClassName,
  autoFocus,
  disabled,
  endDate,
  id,
  isClearable,
  maxDate,
  minDate,
  monthOnly,
  name,
  onChange,
  onClose,
  placeholderText,
  readonly,
  selected,
  selectEnd,
  selectStart,
  startDate,
  type = 'date',
  yearOnly,
  position = undefined,
  portal,
  portalTarget,
}: DatePickerProps) => {
  const { i18n } = useTranslation();
  const [date, setDate] = React.useState<DateObject | null>(
    selected
      ? new DateObject(selected)
      : selectStart && startDate
      ? new DateObject(startDate)
      : selectEnd && endDate
      ? new DateObject(endDate)
      : null,
  );

  const locale = getLocale(i18n.language);

  const onDatePickerChange = (date: DateObject | null) => {
    setDate(date);
    onChange(date?.toDate() || null);
  };

  const initialSelected = useMemo(() => selected, []);
  useEffect(() => {
    // Update internal state if external state changes
    if (selected && selected !== initialSelected) {
      setDate(new DateObject(selected));
    }
  }, [selected, initialSelected]);

  const minDateOrStartDate =
    minDate && startDate ? (minDate.getTime() < startDate.getTime() ? minDate : startDate) : minDate || startDate;
  const maxDateOrEndDate =
    maxDate && endDate ? (maxDate.getTime() > endDate.getTime() ? maxDate : endDate) : maxDate || endDate;

  return (
    <Box className={classnames('datepicker-container', containerClassName)}>
      <BaseDatePicker
        id={id}
        name={name}
        locale={locale}
        className={className}
        format={type === 'date' ? 'MM/DD/YYYY' : 'YYYY/MM/DD HH:mm'}
        value={date || undefined}
        range={false}
        disabled={disabled}
        readOnly={readonly}
        plugins={type === 'datetime' ? [<TimePicker position="bottom" hideSeconds={true} />] : []}
        placeholder={placeholderText}
        minDate={minDateOrStartDate}
        maxDate={maxDateOrEndDate}
        onChange={onDatePickerChange}
        onClose={onClose}
        onOpenPickNewDate={false}
        disableDayPicker={yearOnly || monthOnly}
        disableMonthPicker={false}
        disableYearPicker={false}
        calendarPosition={position}
        portal={portal}
        portalTarget={portalTarget}
        zIndex={1000}
      />
      {isClearable && date ? (
        <Button
          variant={ButtonVariant.Icon}
          color={ButtonColor.Cancel}
          size={ButtonSize.Large}
          onClick={() => setDate(null)}
        >
          <Icon icon={FaTimesCircle} />
        </Button>
      ) : null}
    </Box>
  );
};
