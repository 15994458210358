import classnames from 'classnames';
import { KeyboardEvent, MouseEvent, ReactElement } from 'react';
import { IoMdAdd, IoMdClose } from 'react-icons/io';

export type Props = {
  active?: boolean;
  onClick?: () => Promise<void> | void;
  text: string;
};

export const AddRemovePill = ({ active, onClick, text }: Props): ReactElement => {
  const keyboardEvent = (e: KeyboardEvent<Element>): void => {
    e?.preventDefault();
    e?.stopPropagation();
    e.key === 'Enter' && onClick?.();
  };

  const mouseEvent = (e: MouseEvent<Element>): void => {
    e?.preventDefault();
    e?.stopPropagation();
    onClick?.();
  };

  return (
    <div className={classnames('add-remove-pill', { 'add-remove-pill--active': active })}>
      <div className="add-remove-pill__content" onClick={mouseEvent} onKeyUp={keyboardEvent} tabIndex={0}>
        {text} {active ? <IoMdClose /> : <IoMdAdd />}
      </div>
    </div>
  );
};

export default AddRemovePill;
