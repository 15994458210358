import React, { FC, forwardRef, ReactNode, useEffect, useImperativeHandle } from 'react';
import classnames from 'classnames';

export type TabsRef = {
  selectTab: (tabIdx: number) => void;
};

type TabsProps = {
  tabsContent: { title: string; content: ReactNode | FC<{ active: boolean }> }[];
  selectedTab?: number;
  className?: string;
  GACategory?: string;
  showTabs?: boolean;
  fullWidth?: boolean;
  onTabChange?: (tabIdx: number) => void;
};

export const Tabs = forwardRef<TabsRef, TabsProps>(
  ({ selectedTab = 0, tabsContent, showTabs = false, fullWidth, className = '', onTabChange }, ref) => {
    const [currentTabIdx, setCurrentTabIdx] = React.useState(selectedTab);

    useImperativeHandle(ref, () => ({
      selectTab: (tabIdx: number) => {
        setCurrentTabIdx(tabIdx);
      },
    }));

    useEffect(() => {
      onTabChange && onTabChange(currentTabIdx);
    }, [currentTabIdx, onTabChange]);

    return (
      <div className={classnames('tabs', className)}>
        {showTabs ? (
          <ul className="links u-cf">
            {tabsContent.map((tabContent, idx) => {
              return (
                <li
                  key={idx}
                  className={classnames({
                    active: idx === currentTabIdx,
                    'u-full-width': fullWidth,
                  })}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentTabIdx(idx);
                    }}
                  >
                    {tabContent.title}
                  </a>
                </li>
              );
            })}
          </ul>
        ) : null}

        <ul className="sections">
          {tabsContent.map((tabContent, idx) => {
            const Content = tabContent.content;
            return (
              <li key={idx} className={classnames({ active: idx === currentTabIdx })}>
                {typeof Content === 'function' ? <Content active={idx === currentTabIdx} /> : Content}
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
);
