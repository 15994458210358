import { ModalButton } from '../core/modal/modal-button';
import { CreateEmailTemplateForm, CreateEmailTemplateFormProps } from './create-email-template-form';
import { ButtonProps } from '../core/button/button';

export type CreateEmailTemplateButtonProps = Omit<ButtonProps, 'onClick'> & CreateEmailTemplateFormProps;

export const CreateEmailTemplateButton = ({
  emailType,
  templates,
  backendAdapter,
  onTemplateUpdated,
  ...buttonProps
}: CreateEmailTemplateButtonProps) => {
  return (
    <ModalButton
      body={(close) => (
        <CreateEmailTemplateForm
          emailType={emailType}
          templates={templates}
          backendAdapter={backendAdapter}
          onTemplateUpdated={(...args) => {
            close();
            onTemplateUpdated?.(...args);
          }}
        />
      )}
      {...buttonProps}
    ></ModalButton>
  );
};
