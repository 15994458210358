export enum AvatarSize {
  XSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  XLarge = 'xl',
}

export enum AvatarPull {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}
