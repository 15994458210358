import React, { FC, InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';
import classnames from 'classnames';

type OptionType = Record<string, string> | string[];

export type FormSelectInputInputProps = Omit<
  InputHTMLAttributes<HTMLSelectElement>,
  'name' | 'value' | 'onChange' | 'onBlur'
> &
  GenericFormInputProps & {
    placeholderValue?: string | null;
    fullWidth?: boolean;
    onChange?: (value: string | null) => void;
    sort?: boolean;
  } & (
    | {
        groups?: undefined;
        options: OptionType;
        children?: React.ReactNode;
      }
    | {
        options?: undefined;
        groups: Record<string, OptionType>;
        children?: React.ReactNode;
      }
    | {
        options?: undefined;
        groups?: undefined;
        children: React.ReactNode;
      }
  );

const Options = ({ options, sort }: { options: OptionType; sort?: boolean }) => {
  const optionsObject: Record<string, string> = !Array.isArray(options)
    ? options
    : options.reduce(
        (acc, val) => ({
          ...acc,
          [val]: val,
        }),
        {},
      );

  const sortedOptions = sort
    ? Object.entries(optionsObject).sort((a, b) => {
        if (a[1] < b[1]) {
          return -1;
        }

        if (a[1] > b[1]) {
          return 1;
        }

        return 0;
      })
    : Object.entries(optionsObject);

  return (
    <>
      {sortedOptions.map(([key, val]) => (
        <option key={key} value={key}>
          {val}
        </option>
      ))}
    </>
  );
};

export const FormSelectInput: FC<FormSelectInputInputProps> = ({
  containerClassName,
  className = 'form-input',
  fullWidth = true,
  containerTestId,
  name,
  label,
  labelClassName,
  tooltip,
  required,
  placeholder,
  placeholderValue = null,
  testId,
  groups,
  sort,
  options,
  renderAfter,
  renderBefore,
  onChange: onChangeProp,
  children,
  ...props
}) => {
  const [{ value, onChange, onBlur }, { error, touched }, { setValue }] = useField(name);

  const groupKeys = groups ? Object.keys(groups) : [];

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      label={label}
      labelClassName={labelClassName}
      tooltip={tooltip}
      required={required}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      <fieldset>
        <select
          data-testid={testId || name}
          className={classnames(className, { 'u-full-width': fullWidth })}
          placeholder={placeholder}
          aria-label={label}
          {...props}
          id={name}
          name={name}
          value={value || ''}
          onChange={(evt) => {
            // If placeholder is selected, set value to null so yup.required() fires
            if (evt.target.value === '') {
              setValue(placeholderValue);
              onChangeProp?.(placeholderValue);
              return;
            }

            onChange(evt);
            onChangeProp?.(evt.target.value);
          }}
          onBlur={onBlur}
        >
          {placeholder ? (
            <option className="placeholder" value="">
              {placeholder}
            </option>
          ) : null}
          {children}
          {groups &&
            groupKeys.map((group) => (
              <optgroup key={group} label={group}>
                {Object.entries(groups[group]).map(([key, val]) => (
                  <option key={key} value={key}>
                    {val}
                  </option>
                ))}
                <Options options={groups[group]} sort={sort} />
              </optgroup>
            ))}
          {options && <Options options={options} sort={sort} />}
        </select>
      </fieldset>
    </FormInputContainer>
  );
};
