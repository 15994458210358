import cx from 'classnames';
import { FC, HTMLProps } from 'react';

type Props = {
  children: React.ReactNode;
  padded?: boolean;
  className?: string;
} & HTMLProps<HTMLDivElement>;

export const Card: FC<Props> = ({ children, padded = true, className, ...props }) => {
  return (
    <div
      className={cx('box-border w-full rounded flex flex-col bg-white shadow-light', padded && 'p-[20px]', className)}
      {...props}
    >
      {children}
    </div>
  );
};
