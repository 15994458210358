import React from 'react';
import { GenericFormInputProps } from './types';
import { Icon, IconSize } from '../icon/icon';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'next-i18next';

export type FormLabelProps = Pick<GenericFormInputProps, 'label' | 'required' | 'optional' | 'name'> & {
  tooltip?: string;
  htmlFor?: string;
  className?: string;
};

export const FormLabel = ({ htmlFor, label, required, optional, tooltip, className }: FormLabelProps) => {
  const { t } = useTranslation();

  if (!label) {
    return null;
  }

  // Remove non-alphanumeric characters from the label
  const cleanLabel = label.replace(/[^a-zA-Z0-9]/g, '');

  return (
    <label htmlFor={htmlFor} className={className}>
      {required ? <span className="required">*</span> : null}
      {label}
      {tooltip ? (
        <>
          <Icon icon={FaInfoCircle} className="tooltip-icon" id={`${cleanLabel}-tooltip`} size={IconSize.Small} />
          <Tooltip anchorId={`${cleanLabel}-tooltip`} content={tooltip} place="bottom" />
        </>
      ) : null}
      {optional ? <span className="optional">{t('optional')}</span> : null}
    </label>
  );
};
