import React from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { AccordionTrigger } from './accordion-trigger';
import { AccordionContent } from './accordion-content';
import { Box } from '../layout/box/box';

export type AccordionItem = {
  value?: string;
  title: string | JSX.Element | React.ReactNode;
  content: string | JSX.Element | React.ReactNode;
};

export type AccordionProps = {
  items: AccordionItem[];
  defaultValue?: string;
};

export const Accordion = ({ items, defaultValue }: AccordionProps) => {
  return (
    <Box className="accordion-container">
      <RadixAccordion.Root className="accordion-root" type="single" collapsible defaultValue={defaultValue}>
        {items.map((item, index) => (
          <RadixAccordion.Item className="accordion-item" value={item.value ?? `item-${index}`}>
            <AccordionTrigger title={item.title} />
            <AccordionContent>{item.content}</AccordionContent>
          </RadixAccordion.Item>
        ))}
      </RadixAccordion.Root>
    </Box>
  );
};
