import React, { forwardRef, useImperativeHandle } from 'react';
import { useField } from 'formik';

export type SubjectInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type SubjectInputRef = {
  insertContent: (content: string) => void;
};

export const SubjectInput = forwardRef<SubjectInputRef, SubjectInputProps>((props, forwardedRef) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const [{ value }, , { setValue }] = useField(props.name || 'subject');

  useImperativeHandle(forwardedRef, () => ({
    insertContent: (content: string) => {
      setValue(value + content);
    },
  }));

  return <input ref={ref} {...props} />;
});
