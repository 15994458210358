import React from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';
import classnames from 'classnames';
import { Switch } from '../switch/switch';

export type FormSwitchInputInputProps = GenericFormInputProps & {
  onChange?: (value: boolean) => void;
};

export const FormSwitchInput = ({
  containerClassName,
  containerTestId,
  name,
  placeholder,
  label,
  tooltip,
  required,
  testId,
  onChange,
  renderAfter,
  renderBefore,
}: FormSwitchInputInputProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<boolean | null | undefined>(name);

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      tooltip={tooltip}
      required={required}
      testId={testId}
      containerClassName={classnames('form-input-container', containerClassName)}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      <fieldset id={name}>
        <Switch
          id={name}
          checked={!!value}
          onChange={() => {
            setValue(!value);
            onChange && onChange(!value);

            setTimeout(() => {
              setTouched(true);
            }, 10);
          }}
          label={label}
          subLabel={placeholder}
        />
      </fieldset>
    </FormInputContainer>
  );
};
