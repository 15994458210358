import { ReactElement } from 'react';
import { PipelineHeaderProps } from './types';

export const PipelineHeader = ({ subtitle, title }: PipelineHeaderProps): ReactElement => {
  return (
    <div className="pipeline__header">
      {title} <span>{subtitle}</span>
    </div>
  );
};

export default PipelineHeader;
