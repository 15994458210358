import React, { PropsWithChildren, useEffect, useState } from 'react';
import classnames from 'classnames';
import { HeaderLogo } from './header-logo';
import { HeaderLoader } from './header-loader';

export type HeaderProps = {
  nav: React.ReactNode;
  logo?: React.ReactNode | string;
  dynamicBar?: React.ReactNode;
  secondaryBar?: React.ReactNode;
  loading?: boolean;
  quickLoad?: boolean;
};

const Header = ({
  children,
  nav,
  logo,
  loading = false,
  quickLoad = false,
  dynamicBar = null,
  secondaryBar = null,
}: PropsWithChildren<HeaderProps>) => {
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [coveringContent, setCoveringContent] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > headerHeight) {
        setCoveringContent(true);
      } else {
        setCoveringContent(false);
      }
    };

    window.addEventListener('scroll', checkScroll);
    return () => window.removeEventListener('scroll', checkScroll);
  }, [headerHeight]);

  useEffect(() => {
    const header = headerRef.current;
    if (header) {
      setHeaderHeight(header.clientHeight);
    }
  }, []);

  return (
    <header ref={headerRef} className={classnames('aliro-header', 'u-cf', { 'covering-content': coveringContent })}>
      <div className="aliro-header-wrapper">
        <div>
          {logo ? (
            <div className="logo-container">{typeof logo === 'string' ? <HeaderLogo src={logo} /> : logo}</div>
          ) : null}
          <div className="control-container">
            {nav}
            {children}
          </div>
        </div>
      </div>

      {dynamicBar || null}

      {secondaryBar ? <div className="secondary-bar">{secondaryBar}</div> : null}

      <HeaderLoader loading={loading} quickLoad={quickLoad} />
    </header>
  );
};

export { Header };
