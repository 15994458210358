import { ButtonProps } from '../../core/button/button';
import { ModalButton } from '../../core/modal/modal-button';
import { CreateMediaFolderForm, CreateMediaFolderFormProps } from './create-media-folder-form';

export type CreateMediaFolderButtonProps = Omit<ButtonProps, 'onClick'> & CreateMediaFolderFormProps;

export const CreateMediaFolderButton = ({
  parentId,
  backendAdapter,
  onFolderCreated,
  ...buttonProps
}: CreateMediaFolderButtonProps) => {
  return (
    <ModalButton
      body={(close) => (
        <CreateMediaFolderForm
          backendAdapter={backendAdapter}
          onFolderCreated={(folder) => {
            close();

            if (onFolderCreated) {
              onFolderCreated(folder);
            }
          }}
          parentId={parentId}
        />
      )}
      {...buttonProps}
    ></ModalButton>
  );
};
