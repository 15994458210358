import { useCallback } from 'react';
import axios from 'axios';
import { CreateMediaItemResponse, RemoveMediaItemOpts, UseRemoveMediaItemResult } from '../types';
import { useLoader } from '../../../hooks/useLoader';

export type UseRemoveMediaItemProps = {
  apiBaseUrl: string;
  employerId: string;
  onMediaItemRemoved?: () => void;
};

export const useRemoveMediaItem = (props: UseRemoveMediaItemProps): UseRemoveMediaItemResult => {
  const { apiBaseUrl, employerId, onMediaItemRemoved } = props;
  const { loading, error, run, setError } = useLoader({ cancelOnUnmount: false });

  const removeMediaItem = useCallback(
    async ({ mediaId, folderId = 'root' }: RemoveMediaItemOpts) => {
      if (!mediaId) {
        throw new Error('Media ID is required');
      }
      try {
        const url = `/api/employer/${employerId}/media-folders/${folderId}/media/${mediaId}`;
        const response = axios.delete<CreateMediaItemResponse>(url, { baseURL: apiBaseUrl });
        const result = await run(response);
        if (!result) {
          // Cancelled
          return;
        }
        if (result.data?.success && onMediaItemRemoved) {
          onMediaItemRemoved();
        } else if (!result.data?.success) {
          setError(result.data?.error || 'Unknown Error');
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error?.message || error?.toString() || 'Unknown error');
        }
      }
    },
    [run, employerId, apiBaseUrl, onMediaItemRemoved, setError],
  );

  return {
    error,
    loading,
    removeMediaItem,
  };
};
