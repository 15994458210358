import React, { HTMLAttributes, PropsWithChildren } from 'react';

type margins = 0 | 1 | 2 | 3 | 4 | 'auto';
type paddings = 0 | 1 | 2 | 3 | 4 | 'auto';
type shadows = 0 | 1 | 2 | 3 | 4;
type borderSize = 0 | 1 | 2;
type borderRadius = 0 | 1 | 2 | 3 | 4 | 5;
type colors =
  | 'primary'
  | 'white'
  | 'blue'
  | 'purple'
  | 'cancel'
  | 'draft'
  | 'complete'
  | 'remove'
  | 'light-grey'
  | 'black'
  | 'default'
  | 'dark-default'
  | 'green-cyan'
  | 'pale-green';
type displays = 'block' | 'inline-block' | 'flex' | 'inline-flex' | 'none';
type flexes = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
type flexDirections = 'row' | 'column' | 'row-reverse' | 'column-reverse';
type flexWraps = 'wrap' | 'nowrap';
type flexJustifications = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
type flexAlignments = 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
type gapSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
type textAlignments = 'left' | 'right' | 'center' | 'justify';
type verticalAlignments = 'top' | 'middle' | 'bottom';

export type BoxProps = PropsWithChildren<{
  id?: string;
  onClick?: HTMLAttributes<unknown>['onClick'];
  className?: string;
  display?: displays;
  flex?: flexes;
  bg?: colors;
  color?: colors;
  shadow?: shadows;
  borderSize?: borderSize;
  borderRadius?: borderRadius;
  borderColor?: colors;
  flexDirection?: flexDirections;
  flexWrap?: flexWraps;
  justifyContent?: flexJustifications;
  justifyItems?: flexJustifications;
  justifySelf?: flexJustifications;
  alignContent?: flexAlignments;
  alignItems?: flexAlignments;
  alignSelf?: flexAlignments;
  textAlign?: textAlignments;
  verticalAlign?: verticalAlignments;
  gap?: gapSize;
  m?: margins;
  mt?: margins;
  mr?: margins;
  mb?: margins;
  ml?: margins;
  mx?: margins;
  my?: margins;
  p?: paddings;
  pt?: paddings;
  pr?: paddings;
  pb?: paddings;
  pl?: paddings;
  py?: paddings;
  px?: paddings;
  style?: React.CSSProperties;
  testId?: string;
}>;

export const buildBoxClasses = (props: BoxProps): string[] => {
  const classes = ['u-box'];

  if (props.m !== undefined) classes.push(`u-m-${props.m}`);
  if (props.mt !== undefined) classes.push(`u-mt-${props.mt}`);
  if (props.mr !== undefined) classes.push(`u-mr-${props.mr}`);
  if (props.mb !== undefined) classes.push(`u-mb-${props.mb}`);
  if (props.ml !== undefined) classes.push(`u-ml-${props.ml}`);
  if (props.p !== undefined) classes.push(`u-p-${props.p}`);
  if (props.pt !== undefined) classes.push(`u-pt-${props.pt}`);
  if (props.pr !== undefined) classes.push(`u-pr-${props.pr}`);
  if (props.pb !== undefined) classes.push(`u-pb-${props.pb}`);
  if (props.pl !== undefined) classes.push(`u-pl-${props.pl}`);
  if (props.px !== undefined) {
    classes.push(`u-pl-${props.px}`);
    classes.push(`u-pr-${props.px}`);
  }
  if (props.py !== undefined) {
    classes.push(`u-pt-${props.py}`);
    classes.push(`u-pb-${props.py}`);
  }
  if (props.mx !== undefined) {
    classes.push(`u-ml-${props.mx}`);
    classes.push(`u-mr-${props.mx}`);
  }
  if (props.my !== undefined) {
    classes.push(`u-mt-${props.my}`);
    classes.push(`u-mb-${props.my}`);
  }
  if (props.display !== undefined) classes.push(`u-display-${props.display}`);
  if (props.flex !== undefined) classes.push(`u-flex-${props.flex}`);
  if (props.flexDirection !== undefined) classes.push(`u-flex-direction-${props.flexDirection}`);
  if (props.flexWrap !== undefined) classes.push(`u-flex-wrap-${props.flexWrap}`);
  if (props.justifyContent !== undefined) classes.push(`u-justify-content-${props.justifyContent}`);
  if (props.justifyItems !== undefined) classes.push(`u-justify-items-${props.justifyItems}`);
  if (props.justifySelf !== undefined) classes.push(`u-justify-self-${props.justifySelf}`);
  if (props.alignContent !== undefined) classes.push(`u-align-content-${props.alignContent}`);
  if (props.alignItems !== undefined) classes.push(`u-align-items-${props.alignItems}`);
  if (props.alignSelf !== undefined) classes.push(`u-align-self-${props.alignSelf}`);
  if (props.bg !== undefined) classes.push(`u-bg-${props.bg}`);
  if (props.color !== undefined) classes.push(`u-color-${props.color}`);
  if (props.shadow !== undefined) classes.push(`u-shadow-${props.shadow}`);
  if (props.borderSize !== undefined) classes.push(`u-border-${props.borderSize}`);
  if (props.borderRadius !== undefined) classes.push(`u-border-radius-${props.borderRadius}`);
  if (props.borderColor !== undefined) classes.push(`u-border-color-${props.borderColor}`);
  if (props.textAlign !== undefined) classes.push(`u-text-align-${props.textAlign}`);
  if (props.verticalAlign !== undefined) classes.push(`u-vertical-align-${props.verticalAlign}`);
  if (props.gap !== undefined) classes.push(`u-gap-${props.gap}`);

  if (props.className) classes.push(props.className);

  return classes;
};

export const Box = (props: BoxProps) => {
  const classes = buildBoxClasses(props);
  return (
    <div className={classes.join(' ')} onClick={props.onClick} style={props.style} data-testid={props.testId}>
      {props.children}
    </div>
  );
};
