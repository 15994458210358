import React, { InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';

export type FormNumberedInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'name' | 'value' | 'onChange' | 'onBlur'
> &
  GenericFormInputProps & {
    numberOfInputs: number;
  };

export const FormNumberedInput = ({
  containerClassName = 'form-input-container form-numbered-input-container',
  containerTestId,
  name,
  label,
  icon,
  tooltip,
  required,
  optional,
  placeholder,
  testId,
  renderBefore,
  renderAfter,
  numberOfInputs,
  ...props
}: FormNumberedInputProps) => {
  const [{ value, onChange: onChangeBase }, { error, touched }] = useField<string[]>(name);

  const onChange = (inputNum: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = [...(value || [])];
    newValue[inputNum] = e.target.value;
    onChangeBase({ target: { name, value: newValue } });
  };

  return (
    <FormInputContainer
      name={name}
      error={error}
      errorPosition="before"
      touched={touched}
      label={label}
      icon={icon}
      tooltip={tooltip}
      required={required}
      optional={optional}
      testId={testId}
      placeholder={placeholder}
      containerClassName={containerClassName}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      {Array.from({ length: numberOfInputs }, (_, i) => (
        <fieldset>
          <span className="number-circle">{i + 1}</span>
          <input
            type="text"
            data-testid={(testId || name) + i}
            className="form-input"
            {...props}
            id={`${name}[${i}]`}
            name={`${name}[${i}]`}
            value={value?.[i] || ''}
            onChange={onChange(i)}
          />
        </fieldset>
      ))}
    </FormInputContainer>
  );
};
