import { useCallback } from 'react';
import axios from 'axios';
import { useLoader } from '../../../hooks/useLoader';
import { UpdateEmailTemplateResponse, MailContentTemplate, UseUpdateEmailTemplateResult } from '../types';

export type UseUpdateEmailTemplateOpts = {
  apiBaseUrl: string;
  employerId: string;
  emailType: string;
  onTemplateUpdated?: (template: MailContentTemplate) => void;
};

export const useUpdateEmailTemplate = ({
  apiBaseUrl,
  employerId,
  emailType,
  onTemplateUpdated,
}: UseUpdateEmailTemplateOpts): UseUpdateEmailTemplateResult => {
  const { loading, error, run, setError } = useLoader({ cancelOnUnmount: false });

  const updateEmailTemplate = useCallback(
    (template: MailContentTemplate) => {
      run(
        axios.patch<UpdateEmailTemplateResponse>(`/api/employer/${employerId}/email-templates/${emailType}`, template, {
          baseURL: apiBaseUrl,
        }),
      )
        ?.then((result) => {
          if (!result) {
            // Cancelled
            return;
          }

          if (result?.data?.success && onTemplateUpdated) {
            onTemplateUpdated(template);
          } else if (!result.data?.success) {
            setError(result.data?.error || 'Unknown Error');
          }
        })
        ?.catch((error: Error) => {
          setError(error?.message || error?.toString() || 'Unknown error');
        });
    },
    [apiBaseUrl, employerId, emailType, run, onTemplateUpdated, setError],
  );

  return {
    error,
    loading,
    updateEmailTemplate,
  };
};
