import React from 'react';
import { DatagridFilterProps } from './types';

export const DatagridFilter = <Filter,>({
  onFilterChange,
  filterForm: FilterForm,
  filterState,
}: DatagridFilterProps<Filter>) => {
  return (
    <div className="datagrid-filter">
      <FilterForm filterState={filterState} onFilterChange={onFilterChange} />
    </div>
  );
};
