import React, { MouseEventHandler } from 'react';
import { IconType } from 'react-icons';
import classnames from 'classnames';

export enum PillSize {
  Small = 'small',
  Large = 'large',
}

export enum PillVariant {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type PillProps = (
  | {
      text: React.ReactNode | string;
      children?: undefined;
    }
  | {
      text?: undefined;
      children: React.ReactNode | string;
    }
) & {
  className?: string;
  variant?: PillVariant;
  size?: PillSize;
  testId?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  style?: React.CSSProperties;
} & (
    | {
        canRemove: true;
        onClickRemove: () => void;
        removeIcon?: IconType;
      }
    | {
        canRemove?: false;
      }
  );

export function Pill({
  text,
  children,
  variant = PillVariant.Primary,
  size,
  className,
  testId,
  onClick,
  style,
  ...props
}: PillProps) {
  return (
    <span
      className={classnames(
        {
          pill: true,
          [`pill-${variant}`]: variant,
          [`pill-${size}`]: size,
          clickable: onClick,
        },
        className,
      )}
      style={style}
      data-testid={testId}
      onClick={onClick}
    >
      {text || children}
      {props.canRemove ? (
        <button data-testid="pill-remove-button" onClick={props.onClickRemove}>
          x
        </button>
      ) : null}
    </span>
  );
}
