import { ChangeEvent, PropsWithChildren, useRef } from 'react';
import { useLoader } from '../../../../hooks/useLoader';
import { Button, ButtonProps } from '../../button/button';

export type UploadButtonProps = Omit<ButtonProps, 'onClick'> & {
  onFileSelected: (file: File) => Promise<void>;
  accept: string;
  inputTestId?: string;
};

export function UploadButton({
  onFileSelected,
  accept,
  inputTestId = 'file',
  ...props
}: PropsWithChildren<UploadButtonProps>) {
  const { loading, run } = useLoader({ cancelOnUnmount: false });
  const inputFile = useRef<HTMLInputElement>(null);

  const onClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      run(onFileSelected(file));
    }
  };

  return (
    <>
      <input
        type="file"
        id="file"
        data-testid={inputTestId}
        accept={accept}
        onChange={onChange}
        ref={inputFile}
        style={{ display: 'none' }}
      />
      <Button loading={loading} {...props} onClick={onClick} />
    </>
  );
}
