import { ModalButton } from '../core/modal/modal-button';
import { EmailTemplateManager } from './email-template-manager';
import { useState } from 'react';
import { ButtonProps } from '../core/button/button';
import { defaultEmailTemplateBackendAdapter } from './hooks/default-email-template-backend-adapter';
import { defaultMediaBackendAdapter } from '../media-management/hooks/default-media-backend-adapter';

export type EditEmailTemplateButtonProps = Omit<ButtonProps, 'onClick'> & {
  apiBaseUrl: string;
  emailType: string;
  emailName: string;
  employerId: string;
};

export const EditEmailTemplateButton = ({
  apiBaseUrl,
  employerId,
  emailType,
  emailName,
  ...buttonProps
}: EditEmailTemplateButtonProps) => {
  const backendAdapter = defaultEmailTemplateBackendAdapter({ apiBaseUrl, employerId });
  const mediaBackendAdapter = defaultMediaBackendAdapter({ apiBaseUrl, employerId });
  const [mode, setMode] = useState<'list' | 'edit'>('list');

  return (
    <ModalButton
      modalProps={{
        className: 'modal',
        size: 'medium',
        modal: false,
        overlay: true,
        onInteractOutside: (e) => {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
        },
      }}
      body={() => (
        <EmailTemplateManager
          contentCssUrl={apiBaseUrl + 'api/email-templates/styles.css'}
          emailType={emailType}
          emailName={emailName}
          backendAdapter={backendAdapter}
          mediaBackendAdapter={mediaBackendAdapter}
          onModeChange={setMode}
        />
      )}
      {...buttonProps}
    ></ModalButton>
  );
};
