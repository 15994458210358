import { useFormikContext } from 'formik';
import { DatagridFilterFormProps } from '../datagrid';
import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';

export const FilterOnChange = <FormValues = Record<string, unknown>, FilterState = FormValues>({
  onFilterChange,
  filterState,
  mapValuesToState,
}: Pick<DatagridFilterFormProps<FilterState>, 'onFilterChange' | 'filterState'> & {
  mapValuesToState?: (formValues: FormValues) => FilterState;
}) => {
  const formik = useFormikContext<FormValues>();

  useEffect(() => {
    if (!formik?.isValid) {
      return;
    }

    const newState = mapValuesToState ? mapValuesToState(formik.values) : (formik.values as unknown as FilterState);
    if (!isEqual(newState, filterState)) {
      onFilterChange(newState);
    }
  }, [formik?.values, formik?.isValid]);

  return null;
};
