import React from 'react';
import { components } from 'react-select';
import { BiX } from 'react-icons/bi';

export const ReactSelectClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <BiX />
    </components.ClearIndicator>
  );
};
