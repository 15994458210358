import React, { InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import { GenericFormInputProps } from './types';
import { FormInputContainer } from './form-input-container';
import { Box } from '../layout/box/box';
import classnames from 'classnames';

export type FormRadioInputOption = {
  value: string | boolean;
  label: string;
  sublabel?: string;
  icon?: React.ReactNode;
};

export type FormRadioInputInputProps = Omit<
  InputHTMLAttributes<HTMLElement>,
  'name' | 'value' | 'onChange' | 'onBlur'
> &
  GenericFormInputProps & {
    inline?: boolean;
    radioOptionClassName?: string;
    options: FormRadioInputOption[];
    value?: string | boolean;
    setValue?: (value: string | boolean) => void;
    onChange?: (value: string | boolean) => void;
  };

export const FormRadioInput = ({
  containerClassName = 'radio-group',
  containerTestId,
  name,
  inline,
  label,
  tooltip,
  required,
  placeholder,
  testId,
  renderAfter,
  renderBefore,
  options,
  radioOptionClassName,
  onChange: onChangeProp,
  value: valueProp,
  setValue: setValueProp,
  ...props
}: FormRadioInputInputProps) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(name);

  return (
    <FormInputContainer
      name={name}
      error={error}
      touched={touched}
      label={label}
      tooltip={tooltip}
      required={required}
      testId={testId}
      placeholder={placeholder}
      containerClassName={classnames(containerClassName, { 'radio-group-inline': inline })}
      containerTestId={containerTestId}
      renderBefore={renderBefore}
      renderAfter={renderAfter}
    >
      <fieldset id={name}>
        {options.map(({ value: optValue, label: optLabel, icon: optIcon }, i) => {
          const onClick = () => {
            if (setValueProp) {
              setValueProp(optValue);
            } else {
              setValue(optValue);
            }

            onChangeProp?.(optValue);

            setTimeout(() => {
              setTouched(true);
            }, 10);
          };
          return (
            <Box
              className={classnames('radio-group-option', radioOptionClassName)}
              display="flex"
              flexDirection="row"
              alignItems="baseline"
              key={i}
              mb={4}
              p={1}
              onClick={onClick}
            >
              <input
                onChange={onClick}
                type="radio"
                name={name}
                id={optValue?.toString()}
                value={optValue?.toString()}
                checked={typeof valueProp !== 'undefined' ? valueProp === optValue : value === optValue}
                {...props}
              />
              {optIcon && <span className="option-icon">{optIcon}</span>}
              <label className="option-label">{optLabel}</label>
            </Box>
          );
        })}
      </fieldset>
    </FormInputContainer>
  );
};
