import React from 'react';
import { Box } from '../layout/box/box';
import { AvatarSize } from './types';
import { Avatar } from './avatar';
import { Icon } from '../icon/icon';
import { AiOutlineSync } from 'react-icons/ai';
import classnames from 'classnames';

export type ChangeAvatarProps = {
  img?: string;
  size?: AvatarSize;
  onClick?: () => void;
};

export const ChangeAvatar = ({ img, size, onClick }: ChangeAvatarProps) => {
  return (
    <Box className={classnames('change-avatar-wrapper', { clickable: !!onClick })} onClick={onClick}>
      <Avatar size={size} img={img} />
      <Icon icon={AiOutlineSync} />
    </Box>
  );
};
