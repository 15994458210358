import React, { useRef } from 'react';
import { Grid } from '../core/layout/grid/grid';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Button, ButtonColor, ButtonPull, ButtonSize } from '../core/button/button';
import { EmailEditor } from './editor/email-editor';
import { DynamicDataTemplate, EmailTemplatesBackendAdapter, LOCALE_LABELS, MailContentTemplate } from './types';
import { ConfirmationButton } from '../core/button/confirmation-button';
import { useTranslation } from 'next-i18next';
import { MediaBackendAdapter, MediaItemResponse, MediaManager } from '../media-management';
import { DynamicDataPicker } from './editor/dynamic-data-picker';
import { SubjectInput, SubjectInputRef } from './editor/subject-input';
import { Box, Modal, useWysiwygRef } from '../core';

const validationSchema = object().shape({
  locale: string().required().oneOf(Object.keys(LOCALE_LABELS)).label('Locale'),
  subject: string().required().label('Subject'),
  body: string().required().label('Body'),
});

export type EditEmailTemplateFormProps = {
  initial: MailContentTemplate;
  contentCssUrl: string;
  emailType: string;
  emailName: string;
  onTemplateUpdated: (template: MailContentTemplate) => void;
  backendAdapter: EmailTemplatesBackendAdapter;
  mediaBackendAdapter: MediaBackendAdapter | undefined;
};

export const EditEmailTemplateForm = ({
  initial,
  contentCssUrl,
  emailName,
  emailType,
  onTemplateUpdated,
  backendAdapter,
  mediaBackendAdapter,
}: EditEmailTemplateFormProps) => {
  const bodyRef = useWysiwygRef();
  const subjectRef = useRef<SubjectInputRef>(null);
  const { t } = useTranslation();
  const { error, updateEmailTemplate } = backendAdapter.useUpdateEmailTemplate({ emailType, onTemplateUpdated });
  const { templates } = backendAdapter.useEmailTemplateDynamicData({ emailType });
  const [lastActiveInput, setLastActiveInput] = React.useState<'body' | 'subject'>('body');
  const [mediaOpen, setMediaOpen] = React.useState(false);
  const [dynamicDataOpen, setDynamicDataOpen] = React.useState(false);
  const onOpenMedia = () => setMediaOpen(true);
  const onCloseMedia = () => setMediaOpen(false);
  const onOpenDynamicData = () => setDynamicDataOpen(true);
  const onCloseDynamicData = () => setDynamicDataOpen(false);

  const onFocusBody = () => setLastActiveInput('body');
  const onFocusSubject = () => setLastActiveInput('subject');

  const onInsertMediaItem = (mediaItem: MediaItemResponse) => {
    bodyRef?.current?.insertContent(
      `<img data-media-id='${mediaItem._id}' src='${mediaItem.publicUrl}' width='${mediaItem.width}' height='${mediaItem.height}'/>`,
    );
    onCloseMedia();
  };

  const onInsertDynamicDataTemplate = (dataTemplate: DynamicDataTemplate) => {
    console.log('onInsertDynamicDataTemplate', dataTemplate);

    if (lastActiveInput === 'body') {
      bodyRef?.current?.insertContent(
        `<span data-data-key='${dataTemplate.key}' class='dynamic'>{{ ${dataTemplate.key} }}</span>`,
      );
    } else {
      subjectRef?.current?.insertContent(`{{ ${dataTemplate.key} }}`);
    }
    onCloseDynamicData();
  };

  const { buildPreview } = backendAdapter.useBuildEmailTemplatePreview({ emailType });
  const onOpenPreview = async () => {
    const previewContent = await buildPreview(bodyRef?.current?.getContent() || '', 'body');
    bodyRef?.current?.openPreview(previewContent ?? '');
  };

  return (
    <>
      <Formik
        initialValues={{ locale: initial.locale, subject: initial.subject, body: initial.body }}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={true}
        validateOnMount={false}
        isInitialValid={true}
        onSubmit={updateEmailTemplate}
      >
        {({ handleSubmit, handleChange, handleBlur, submitForm, isSubmitting, errors, values, setFieldValue }) => {
          return (
            <Grid.Container fluid>
              <Grid.Row>
                <Grid.Column
                  colspan={12}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  alignContent="center"
                >
                  <h5 className="u-mb-0">
                    {emailName} ({LOCALE_LABELS[initial.locale]})
                  </h5>

                  <Box className="actions" display="flex" flexDirection="row" alignItems="center" alignContent="center">
                    {mediaBackendAdapter ? (
                      <Button
                        size={ButtonSize.Small}
                        color={ButtonColor.Complete}
                        className="u-ml-2 u-mb-0"
                        onClick={onOpenMedia}
                        data-testid="insert-media"
                      >
                        {t('emailTemplates.insertMedia')}
                      </Button>
                    ) : null}
                    <Button
                      size={ButtonSize.Small}
                      color={ButtonColor.Complete}
                      className="u-ml-2 u-mb-0"
                      onClick={onOpenDynamicData}
                      data-testid="insert-data"
                    >
                      {t('emailTemplates.insertDynamicData')}
                    </Button>
                    <Button
                      size={ButtonSize.Small}
                      color={ButtonColor.Blue}
                      className="u-ml-2 u-mb-0"
                      onClick={onOpenPreview}
                      data-testid="preview"
                    >
                      {t('emailTemplates.preview')}
                    </Button>
                  </Box>
                </Grid.Column>
              </Grid.Row>

              {error && (
                <Grid.Row className="error-messages">
                  <Grid.Column colspan={12}>
                    <p>{error}</p>
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row>
                <Grid.Column colspan={12}>
                  <form onSubmit={handleSubmit}>
                    {errors.subject ? <p className="error-message">{errors.subject}</p> : null}
                    <SubjectInput
                      ref={subjectRef}
                      id="subject"
                      name="subject"
                      type="text"
                      className="u-full-width"
                      placeholder="Subject"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.subject}
                      onFocus={onFocusSubject}
                    />

                    {errors.body ? <p className="error-message">{errors.body}</p> : null}
                    {templates ? (
                      <EmailEditor
                        ref={bodyRef}
                        contentCssUrl={contentCssUrl}
                        backendAdapter={backendAdapter}
                        emailType={emailType}
                        templates={templates}
                        initialVal={initial.body}
                        onChange={(val) => setFieldValue('body', val)}
                        onFocus={onFocusBody}
                      />
                    ) : null}

                    <ConfirmationButton
                      type="button"
                      pull={ButtonPull.Right}
                      color={ButtonColor.Primary}
                      loading={isSubmitting}
                      disabled={isSubmitting}
                      onClick={submitForm}
                      className="u-mt-2"
                      confirmation={{
                        title: t('emailTemplates.save.confirmTitle'),
                        message: t('emailTemplates.save.confirmMessage'),
                        confirm: t('emailTemplates.save.confirmButton'),
                        cancel: t('emailTemplates.save.cancelButton'),
                      }}
                    >
                      {t('emailTemplates.save.confirmTitle')}
                    </ConfirmationButton>
                  </form>
                </Grid.Column>
              </Grid.Row>
            </Grid.Container>
          );
        }}
      </Formik>

      {mediaBackendAdapter && mediaOpen ? (
        <Modal
          open={true}
          onClose={onCloseMedia}
          size="large"
          onInteractOutside={(e: Event) => {
            e?.stopPropagation();
            e?.stopImmediatePropagation();
            e?.preventDefault();
          }}
        >
          <MediaManager backendAdapter={mediaBackendAdapter} onInsertMediaItem={onInsertMediaItem} />
        </Modal>
      ) : null}
      {dynamicDataOpen ? (
        <Modal open={true} onClose={onCloseDynamicData}>
          <DynamicDataPicker
            templates={lastActiveInput === 'body' ? templates : templates.filter((t) => t.type === 'value')}
            onTemplatePicked={onInsertDynamicDataTemplate}
          />
        </Modal>
      ) : null}
    </>
  );
};
