import * as RadixMenu from '@radix-ui/react-dropdown-menu';
import { FC } from 'react';

type Props = {
  children: React.ReactNode;
  modal?: boolean;
  onOpenChange?: (open: boolean) => void;
  open?: boolean;
  trigger: React.ReactNode;
};

export const DropdownMenu: FC<Props> = ({ children, modal, onOpenChange, open, trigger }) => {
  return (
    <RadixMenu.Root open={open} onOpenChange={onOpenChange} modal={modal}>
      {trigger && <RadixMenu.Trigger asChild>{trigger}</RadixMenu.Trigger>}
      <RadixMenu.Portal>
        <RadixMenu.Content
          className="slideIn p-0 mx-4 flex flex-col gap-2 bg-white border border-gray-100 border-solid rounded shadow z-[101]"
          onClick={(e) => {
            e.stopPropagation();
            onOpenChange?.(false);
          }}
        >
          <RadixMenu.Arrow className="fill-gray-100" />
          {children}
        </RadixMenu.Content>
      </RadixMenu.Portal>
    </RadixMenu.Root>
  );
};
