import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, ButtonSize } from '../button/button';
import { BsX } from 'react-icons/bs';

export type AlertActionProps = (
  | {
      type?: 'link';
      label: string;
    }
  | {
      type: 'button';
      label: string | React.ReactNode;
      buttonProps?: Omit<React.ComponentProps<typeof Button>, 'children'>;
    }
) &
  (
    | {
        href: string;
        onClick?: never;
      }
    | {
        href?: never;
        onClick: () => void;
      }
  );

export enum AlertSeverity {
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
  Success = 'success',
}

export type AlertProps = {
  title: string;
  message?: string | React.ReactNode;
  severity: AlertSeverity;
  action?: AlertActionProps;
  dismissKey?: string;
  icon?: React.ReactNode;
};

export const AlertAction = (props: AlertActionProps) => {
  if (!props) {
    return null;
  }

  if (props.type === 'button') {
    return (
      <Button
        size={ButtonSize.Small}
        onClick={() => {
          if (props.onClick) {
            return props.onClick();
          }

          window.location.href = props.href;
        }}
        {...(props.buttonProps || {})}
      >
        {props.label}
      </Button>
    );
  }

  return (
    <a
      href={props.href}
      onClick={
        props.onClick
          ? (e) => {
              e.preventDefault();
              props.onClick();
            }
          : undefined
      }
    >
      {props.label}
    </a>
  );
};

const buildDismissKey = (key: string) => {
  return 'alerts.' + key;
};

export const Alert = ({ title, message, severity, action, dismissKey, icon }: AlertProps) => {
  const isDismissed = !!dismissKey && !!localStorage.getItem(buildDismissKey(dismissKey));
  const [displayed, setDisplayed] = useState(!isDismissed);

  if (!displayed) {
    return null;
  }

  const displayDismissButton = !!dismissKey;

  const handleDismiss = () => {
    localStorage.setItem('alerts.' + dismissKey, 'true');
    setDisplayed(false);
  };

  return (
    <div className={classNames({ alert: true, [severity]: true, 'shadow-light': true })}>
      <div className="content flex flex-row">
        {!!icon && <div className="alert-icon hidden md:block">{icon}</div>}
        <div className="flex flex-col w-full">
          <div className="w-full flex flex-row flex-end justify-between">
            {title ? <div className="title font-bold p-0 m-0">{title}</div> : null}
            {displayDismissButton && (
              <BsX
                role="button"
                onClick={handleDismiss}
                className="text-offblack cursor-pointer rounded my-auto hover:bg-black/20"
              />
            )}
          </div>
          {message ? <p>{message}</p> : null}
          {action && (
            <div className="action">
              <AlertAction {...action} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
