import React, { useCallback, useEffect } from 'react';
import { EmailTemplateList } from './email-template-list';
import { CreateEmailTemplateButton } from './create-email-template-button';
import { ButtonColor, ButtonPull, ButtonSize } from '../core/button/button';
import { EditEmailTemplateForm } from './edit-email-template-form';
import { EmailTemplatesBackendAdapter, MailContentFollowup, MailContentTemplate } from './types';
import { Tabs } from '../core/layout/tabs/tabs';
import { FollowupEmailTemplateList } from './followup-email-template-list';
import { useTranslation } from 'next-i18next';
import { MediaBackendAdapter } from '../media-management';
import { Modal } from '../core/modal/modal';

export type EmailTemplateManagerProps = {
  contentCssUrl: string;
  emailType: string;
  emailName: string;
  onModeChange?: (mode: 'list' | 'edit') => void;
  backendAdapter: EmailTemplatesBackendAdapter;
  mediaBackendAdapter: MediaBackendAdapter | undefined;
};

type SelectedTemplate = MailContentTemplate & Pick<EmailTemplateManagerProps, 'emailType' | 'emailName'>;

export function EmailTemplateManager({
  contentCssUrl,
  emailType,
  emailName,
  onModeChange,
  backendAdapter,
  mediaBackendAdapter,
}: EmailTemplateManagerProps) {
  const { loading, templates, followups, reload } = backendAdapter.useEmailTemplates({ emailType });
  const { deleteEmailTemplate } = backendAdapter.useDeleteEmailTemplate({ emailType, onTemplateDeleted: reload });
  const { t } = useTranslation();

  const buildFollowupName = useCallback(
    (followup: MailContentFollowup) =>
      `${t(followup.recurring ? 'emailTemplates.followups.recurringFollowup' : 'emailTemplates.followups.followup')} (${
        followup.delayInDays
      } ${t('emailTemplates.followups.days')})`,
    [t],
  );

  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [selectedTemplate, setSelectedTemplate] = React.useState<SelectedTemplate>();

  const selectTemplate = useCallback(
    (template: MailContentTemplate) => {
      const { emailType: selectedEmailType, emailName: selectedEmailName } =
        activeTab === 0
          ? { emailType, emailName }
          : { emailType: followups[activeTab - 1].type, emailName: buildFollowupName(followups[activeTab - 1]) };
      setSelectedTemplate({ ...template, emailType: selectedEmailType, emailName: selectedEmailName });
    },
    [buildFollowupName, activeTab, emailName, emailType, followups],
  );

  useEffect(() => {
    if (onModeChange) {
      onModeChange(selectedTemplate ? 'edit' : 'list');
    }
  }, [selectedTemplate, onModeChange]);

  return (
    <div className="email-template-manager">
      <h5 className="u-mb-4">{emailName}</h5>

      {followups && followups.length > 0 ? (
        <Tabs
          showTabs
          onTabChange={setActiveTab}
          selectedTab={activeTab}
          tabsContent={[
            {
              title: t('emailTemplates.followups.initial'),
              content: (
                <>
                  <EmailTemplateList
                    templates={templates}
                    onEditTemplate={selectTemplate}
                    onDeleteTemplate={deleteEmailTemplate}
                  />
                  <CreateEmailTemplateButton
                    size={ButtonSize.Small}
                    fullWidth={true}
                    color={ButtonColor.Primary}
                    pull={ButtonPull.Right}
                    emailType={emailType}
                    templates={templates}
                    onTemplateUpdated={(template) => {
                      reload().then(() => {
                        selectTemplate(template);
                      });
                    }}
                    backendAdapter={backendAdapter}
                  >
                    {t('emailTemplates.addNewLocale')}
                  </CreateEmailTemplateButton>
                </>
              ),
            },
            ...followups.map((followup, index) => ({
              title: buildFollowupName(followup),
              content: (
                <FollowupEmailTemplateList
                  onClickTemplate={selectTemplate}
                  emailType={followup.type}
                  backendAdapter={backendAdapter}
                />
              ),
            })),
          ]}
        />
      ) : templates?.length ? (
        <>
          <EmailTemplateList
            templates={templates}
            onEditTemplate={selectTemplate}
            onDeleteTemplate={deleteEmailTemplate}
          />
          <CreateEmailTemplateButton
            size={ButtonSize.Small}
            fullWidth={true}
            color={ButtonColor.Primary}
            pull={ButtonPull.Right}
            emailType={emailType}
            templates={templates}
            backendAdapter={backendAdapter}
            onTemplateUpdated={(template) => {
              reload().then(() => {
                selectTemplate(template);
              });
            }}
            className="u-mt-3"
          >
            {t('emailTemplates.addNewLocale')}
          </CreateEmailTemplateButton>
        </>
      ) : !loading ? (
        <p className="u-pull-center">{t('emailTemplates.noTemplates')}</p>
      ) : (
        <p className="u-pull-center">{t('emailTemplates.loadingTemplates')}</p>
      )}

      {selectedTemplate ? (
        <Modal
          className="modal modal-email-template-editor"
          open={true}
          onClose={() => setSelectedTemplate(undefined)}
          size="large"
          onInteractOutside={(e: any) => {
            e.preventDefault();
          }}
          modal={false}
          overlay={true}
        >
          <EditEmailTemplateForm
            contentCssUrl={contentCssUrl}
            emailType={selectedTemplate.emailType}
            emailName={selectedTemplate.emailName}
            initial={{
              locale: selectedTemplate.locale,
              subject: selectedTemplate.subject,
              body: selectedTemplate.body,
            }}
            backendAdapter={backendAdapter}
            mediaBackendAdapter={mediaBackendAdapter}
            onTemplateUpdated={() => {
              reload().then(() => {
                setSelectedTemplate(undefined);
              });
            }}
          />
        </Modal>
      ) : null}
    </div>
  );
}
