import { HTMLAttributes, PropsWithChildren } from 'react';

export type GridContainerProps = {
  className?: string;
  fluid?: boolean;
} & HTMLAttributes<unknown>;

export const GridContainer = ({ children, className, fluid, ...props }: PropsWithChildren<GridContainerProps>) => {
  const classes = [fluid ? 'grid--fluid' : 'grid'];
  if (className) classes.push(className);

  return (
    <div className={classes.join(' ')} {...props}>
      {children}
    </div>
  );
};
