import { MediaBackendAdapter } from '../types';
import { useCreateFolder } from './useCreateFolder';
import { useCreateMediaItem } from './useCreateMediaItem';
import { useFolderTraverse } from './useFolderTraverse';
import { useMediaItemSearch } from './useMediaItemSearch';
import { useRemoveMediaItem } from './useRemoveMediaItem';

export const defaultMediaBackendAdapter = ({
  apiBaseUrl,
  employerId,
}: {
  apiBaseUrl: string;
  employerId: string;
}): MediaBackendAdapter => ({
  useCreateFolder: (opts) => useCreateFolder({ apiBaseUrl, employerId, ...opts }),
  useCreateMediaItem: (opts) => useCreateMediaItem({ apiBaseUrl, employerId, ...opts }),
  useFolderTraverse: () => useFolderTraverse({ apiBaseUrl, employerId }),
  useMediaItemSearch: (opts) => useMediaItemSearch({ apiBaseUrl, employerId, ...opts }),
  useRemoveMediaItem: (opts) => useRemoveMediaItem({ apiBaseUrl, employerId, ...opts }),
});
