import React from 'react';
import classnames from 'classnames';

export type ProgressBarProps = {
  className?: string;
  barClassName?: string;
  label?: string | boolean;
  labelClassName?: string;
  percent: number;
};

export const ProgressBar = ({
  className = 'bg-gray-100 h-3',
  barClassName = 'bg-success h-3',
  labelClassName = 'text-sm font-light text-gray-600',
  percent = 100,
  label = false,
}: ProgressBarProps) => {
  const percentCleaned = Math.round(Math.min(Math.max(0, percent), 100));
  return (
    <div className="flex flex-1 flex-row items-center">
      <div className={classnames('w-full rounded-full', className)}>
        <div
          className={classnames('text-center leading-none rounded-full', barClassName)}
          style={{ width: `${percentCleaned}%` }}
        />
      </div>
      {label ? (
        <label className={classnames('m-0 ml-3', labelClassName)}>
          {typeof label === 'string' ? label : `${percentCleaned}%`}
        </label>
      ) : null}
    </div>
  );
};
