import { DynamicDataTemplate } from '../types';

const templateRegexForKey = (key: string) => new RegExp(`{{\\s?${key}\\s?}}`, 'ig');

export const addEditorMarkupToTemplate = (templates: DynamicDataTemplate[], template: string): string => {
  const htmlTemplates = templates.filter((t) => t.type === 'html');
  for (const htmlTemplate of htmlTemplates) {
    const { key } = htmlTemplate;
    template = template.replace(templateRegexForKey(key), `<span data-key="$1" class="dynamic">{{ ${key} }}</span>`);
  }

  return template;
};

export const buildPreviewContent = (templates: DynamicDataTemplate[], template: string): string => {
  let previewContent = template;
  for (let i = 0; i < templates.length; i++) {
    const { key, ejs } = templates[i];

    previewContent = previewContent.replace(templateRegexForKey(key), ejs);
  }

  return previewContent;
};

export const removeEditorMarkupFromTemplate = (template: string): string => {
  return template.replace(
    new RegExp(`<span\\s+data-key="(.+?)"\\s+class="dynamic">{{\\s?([a-zA-Z0-9-_]{5,})\\s?}}</span>`, 'ig'),
    '{{ $1 }}',
  );
};
