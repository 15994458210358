import { Box, BoxProps } from '../box/box';

export type COLSPANS = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type HiddenUpDown = 'up' | 'down';

export type GridColumnProps = {
  colspan: COLSPANS;
  colspanSm?: COLSPANS;
  colspanMd?: COLSPANS;
  colspanLg?: COLSPANS;
  colspanXl?: COLSPANS;
  offset?: COLSPANS;
  offsetSm?: COLSPANS;
  offsetMd?: COLSPANS;
  offsetLg?: COLSPANS;
  offsetXl?: COLSPANS;
  hidden?: boolean;
  hiddenSm?: HiddenUpDown;
  hiddenMd?: HiddenUpDown;
  hiddenLg?: HiddenUpDown;
  hiddenXl?: HiddenUpDown;
} & BoxProps;

export const buildGridColumnClasses = (props: GridColumnProps): string[] => {
  const classes = [`grid__col-${props.colspan}`];
  if (props.className) classes.push(props.className);
  if (props.colspanSm) classes.push(`grid__col-sm-${props.colspanSm}`);
  if (props.colspanMd) classes.push(`grid__col-md-${props.colspanMd}`);
  if (props.colspanLg) classes.push(`grid__col-lg-${props.colspanLg}`);
  if (props.colspanXl) classes.push(`grid__col-xl-${props.colspanXl}`);
  if (props.offset) classes.push(`grid__col-offset-${props.offset}`);
  if (props.offsetSm) classes.push(`grid__col-sm-offset-${props.offsetSm}`);
  if (props.offsetMd) classes.push(`grid__col-md-offset-${props.offsetMd}`);
  if (props.offsetLg) classes.push(`grid__col-lg-offset-${props.offsetLg}`);
  if (props.offsetXl) classes.push(`grid__col-xl-offset-${props.offsetXl}`);
  if (props.hidden) classes.push('grid__col-hidden');
  if (props.hiddenSm) classes.push(`grid__col-sm-hidden-${props.hiddenSm}`);
  if (props.hiddenMd) classes.push(`grid__col-md-hidden-${props.hiddenMd}`);
  if (props.hiddenLg) classes.push(`grid__col-lg-hidden-${props.hiddenLg}`);
  if (props.hiddenXl) classes.push(`grid__col-xl-hidden-${props.hiddenXl}`);

  return classes;
};

export const GridColumn = (props: GridColumnProps) => {
  const classes = buildGridColumnClasses(props);
  return <Box p={2} {...props} className={classes.join(' ')} />;
};
