import { Locale } from 'react-date-object';
import enLocale from 'react-date-object/locales/gregorian_en';

const locales: Record<string, Locale> = {
  'en-us': enLocale,
  'en-gb': enLocale,
  'es-es': {
    name: 'gregorian_es',
    months: [
      ['Enero', 'Enero'],
      ['Febrero', 'Feb'],
      ['Marzo', 'Mar'],
      ['Abril', 'Abr'],
      ['Mayo', 'May'],
      ['Junio', 'Jun'],
      ['Julio', 'Jul'],
      ['Agosto', 'Ago'],
      ['Septiembre', 'Sep'],
      ['Octubre', 'Oct'],
      ['Noviembre', 'Nov'],
      ['Diciembre', 'Dic'],
    ],
    weekDays: [
      ['Sábado', 'Sa'],
      ['Domingo', 'Do'],
      ['Lunes', 'Lu'],
      ['Martes', 'Ma'],
      ['Miércoles', 'Mi'],
      ['Jueves', 'Ju'],
      ['Viernes', 'Vi'],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
      ['AM', 'am'],
      ['PM', 'pm'],
    ],
  },
  'es-419': {
    name: 'gregorian_es',
    months: [
      ['Enero', 'Enero'],
      ['Febrero', 'Feb'],
      ['Marzo', 'Mar'],
      ['Abril', 'Abr'],
      ['Mayo', 'May'],
      ['Junio', 'Jun'],
      ['Julio', 'Jul'],
      ['Agosto', 'Ago'],
      ['Septiembre', 'Sep'],
      ['Octubre', 'Oct'],
      ['Noviembre', 'Nov'],
      ['Diciembre', 'Dic'],
    ],
    weekDays: [
      ['Sábado', 'Sa'],
      ['Domingo', 'Do'],
      ['Lunes', 'Lu'],
      ['Martes', 'Ma'],
      ['Miércoles', 'Mi'],
      ['Jueves', 'Ju'],
      ['Viernes', 'Vi'],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
      ['AM', 'am'],
      ['PM', 'pm'],
    ],
  },
  'de-de': {
    name: 'gregorian_de',
    months: [
      ['Januar', 'Jan'],
      ['Februar', 'Feb'],
      ['März', 'Mär'],
      ['April', 'Apr'],
      ['Mai', 'Mai'],
      ['Juni', 'Jun'],
      ['Juli', 'Jul'],
      ['August', 'Aug'],
      ['September', 'Sep'],
      ['Oktober', 'Okt'],
      ['November', 'Nov'],
      ['Dezember', 'Dez'],
    ],
    weekDays: [
      ['Samstag', 'Sa'],
      ['Sonntag', 'So'],
      ['Montag', 'Mo'],
      ['Dienstag', 'Di'],
      ['Mittwoch', 'Mi'],
      ['Donnerstag', 'Do'],
      ['Freitag', 'Fr'],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
      ['AM', 'am'],
      ['PM', 'pm'],
    ],
  },
  'fr-ca': {
    name: 'gregorian_fr',
    months: [
      ['Janvier', 'Jan'],
      ['Février', 'Fév'],
      ['Mars', 'Mar'],
      ['Avril', 'Avr'],
      ['Mai', 'Mai'],
      ['Juin', 'Jun'],
      ['Juillet', 'Jul'],
      ['Août', 'Aoû'],
      ['Septembre', 'Sep'],
      ['Octobre', 'Oct'],
      ['Novembre', 'Nov'],
      ['Décembre', 'Déc'],
    ],
    weekDays: [
      ['Samedi', 'Sa'],
      ['Dimanche', 'Di'],
      ['Lundi', 'Lu'],
      ['Mardi', 'Ma'],
      ['Mercredi', 'Me'],
      ['Jeudi', 'Je'],
      ['Vendredi', 'Ve'],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
      ['AM', 'am'],
      ['PM', 'pm'],
    ],
  },
  'it-it': {
    name: 'gregorian_it',
    months: [
      ['Gennaio', 'Gen'],
      ['Febbraio', 'Feb'],
      ['Marzo', 'Mar'],
      ['Aprile', 'Apr'],
      ['Maggio', 'Mag'],
      ['Giugno', 'Giu'],
      ['Luglio', 'Lug'],
      ['Agosto', 'Ago'],
      ['Settembre', 'Set'],
      ['Ottobre', 'Ott'],
      ['Novembre', 'Nov'],
      ['Dicembre', 'Dic'],
    ],
    weekDays: [
      ['Sabato', 'Sa'],
      ['Domenica', 'Do'],
      ['Lunedì', 'Lu'],
      ['Martedì', 'Ma'],
      ['Mercoledì', 'Me'],
      ['Giovedì', 'Gi'],
      ['Venerdì', 'Ve'],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
      ['AM', 'am'],
      ['PM', 'pm'],
    ],
  },
};

export const getLocale = (locale: string): Locale => {
  if (!locale) {
    return locales['en-us'];
  }

  return locales[locale.toLowerCase()] || locales['en-us'];
};
