import { ButtonProps } from '../../core/button/button';
import { UseCreateMediaItemOpts } from '../hooks/useCreateMediaItem';
import { UploadButton } from '../../core/upload/upload-button/upload-button';
import { MediaBackendAdapter } from '../types';

export type CreateMediaItemButtonProps = Omit<ButtonProps, 'onClick'> &
  Pick<UseCreateMediaItemOpts, 'folderId' | 'onMediaItemCreated'> & {
    backendAdapter: MediaBackendAdapter;
  };

export const CreateMediaItemButton = ({
  backendAdapter,
  folderId,
  onMediaItemCreated,
  children,
  ...buttonProps
}: CreateMediaItemButtonProps) => {
  const { createMediaItem, uploadProgress } = backendAdapter.useCreateMediaItem({
    folderId,
    onMediaItemCreated,
  });

  const onFileSelected = (image: File) => createMediaItem({ image });

  return (
    <UploadButton onFileSelected={onFileSelected} accept="image/jpeg,image/png" {...buttonProps}>
      {children}
      {uploadProgress ? ` (${uploadProgress}%)` : ''}
    </UploadButton>
  );
};
