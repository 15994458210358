import React, { useMemo } from 'react';
import { Grid } from '../core/layout/grid/grid';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { Button, ButtonColor, ButtonPull } from '../core/button/button';
import { EmailTemplatesBackendAdapter, Locale, LOCALE_LABELS, MailContentTemplate } from './types';
import { useTranslation } from 'next-i18next';
import { FormSelectInput } from '../core';

const validationSchema = object().shape({
  locale: string().required().oneOf(Object.keys(LOCALE_LABELS)).label('Locale'),
  subject: string().label('Subject'),
  body: string().label('Body'),
});

export type CreateEmailTemplateFormProps = {
  templates: MailContentTemplate[];
  emailType: string;
  onTemplateUpdated: (template: MailContentTemplate) => void;
  backendAdapter: EmailTemplatesBackendAdapter;
};

export const CreateEmailTemplateForm = ({
  emailType,
  templates: existingTemplates,
  backendAdapter,
  onTemplateUpdated,
}: CreateEmailTemplateFormProps) => {
  const { t } = useTranslation();
  const { error, updateEmailTemplate } = backendAdapter.useUpdateEmailTemplate({ emailType, onTemplateUpdated });

  const defaultTemplate = useMemo(() => {
    return existingTemplates?.length > 0
      ? existingTemplates.find((t) => t.locale === 'en-US') || existingTemplates[0]
      : { subject: '', body: '' };
  }, [existingTemplates]);

  const missingLocales = useMemo(() => {
    return Object.keys(LOCALE_LABELS).filter(
      (locale) => !existingTemplates.some((t) => t.locale === locale),
    ) as Locale[];
  }, [existingTemplates]);

  return (
    <Formik
      initialValues={{ ...defaultTemplate, locale: missingLocales[0] }}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={updateEmailTemplate}
    >
      {({ handleSubmit, handleChange, handleBlur, isSubmitting, errors, values }) => (
        <Grid.Container fluid>
          {error && (
            <Grid.Row className="error-messages">
              <Grid.Column colspan={12}>
                <p>{error}</p>
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column colspan={12}>
              <form onSubmit={handleSubmit}>
                <FormSelectInput
                  name="locale"
                  label={t('emailTemplates.selectLocale')}
                  fullWidth={true}
                  placeholderValue=""
                  options={missingLocales.reduce((locales, locale) => {
                    locales[locale] = LOCALE_LABELS[locale];

                    return locales;
                  }, {} as Record<string, string>)}
                  autoComplete="off"
                />
                <Button type="submit" pull={ButtonPull.Right} color={ButtonColor.Primary} loading={isSubmitting}>
                  {t('emailTemplates.create')}
                </Button>
              </form>
            </Grid.Column>
          </Grid.Row>
        </Grid.Container>
      )}
    </Formik>
  );
};
