import { KeyboardEvent, ReactElement, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { MdTune } from 'react-icons/md';
import { Button, ButtonColor } from '../button/button';
import { Input, Option, Props, SearchSortFilterInput, Select } from './types';

export const SearchSortFilter = ({ button, displayOnInitialisation = true, elements, header }: Props): ReactElement => {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(displayOnInitialisation);

  return (
    <div className="search-sort-filter">
      <div className="search-sort-filter__header">
        {header}
        <MdTune
          size={21.34}
          onClick={() => setIsDisplayed(!isDisplayed)}
          onKeyUp={(e: KeyboardEvent<SVGElement>) => e.key === 'Enter' && setIsDisplayed(!isDisplayed)}
          role="button"
          tabIndex={0}
        />
      </div>
      {isDisplayed && (
        <div className="search-sort-filter__body">
          {elements.map(({ element, label }: SearchSortFilterInput, index: number) => (
            <div key={index} className="search-sort-filter__input-wrapper">
              <label htmlFor={element.id}>{label}</label>
              {element.type === 'select' ? <SelectInput {...(element as Select)} /> : <input {...(element as Input)} />}
            </div>
          ))}
          {button && (
            <div className="search-sort-filter__input-wrapper">
              <Button
                color={ButtonColor.Secondary}
                data-testid="search-sort-filter-button"
                disabled={button.disabled}
                onClick={button.onClick}
                type="button"
              >
                {button.text}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const SelectInput = ({ id, options, title, type, ...props }: Select): ReactElement => {
  return (
    <div className="search-sort-filter__select-wrapper">
      <select id={id} title={title ?? id} {...props}>
        {options.map(({ displayValue, ...optionProps }: Option, index: number) => (
          <option key={index} {...optionProps}>
            {displayValue}
          </option>
        ))}
      </select>
      <FaChevronDown />
    </div>
  );
};

export default SearchSortFilter;
