import { useCallback, useState } from 'react';
import axios from 'axios';
import { CreateMediaItemOpts, CreateMediaItemResponse, MediaItemResponse, UseCreateMediaItemResult } from '../types';
import { useLoader } from '../../../hooks/useLoader';
import throttle from 'lodash/debounce';

export type UseCreateMediaItemOpts = {
  apiBaseUrl: string;
  employerId: string;
  folderId: string | null;
  onMediaItemCreated?: (item: MediaItemResponse) => void;
};

export const useCreateMediaItem = ({
  apiBaseUrl,
  employerId,
  folderId,
  onMediaItemCreated,
}: UseCreateMediaItemOpts): UseCreateMediaItemResult => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const { loading, error, run, setError } = useLoader({ cancelOnUnmount: false });

  const createMediaItem = useCallback(
    ({ image }: CreateMediaItemOpts) => {
      const data = new FormData();
      data.append('image', image);

      return run(
        axios.post<CreateMediaItemResponse>(
          `/api/employer/${employerId}/media-folders/${folderId || 'root'}/media`,
          data,
          {
            baseURL: apiBaseUrl,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: throttle(
              (progressEvent) => {
                setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
              },
              250,
              { leading: true, trailing: true },
            ),
          },
        ),
      )
        ?.then((result) => {
          if (!result) {
            // Cancelled
            return;
          }

          if (result.data?.success && onMediaItemCreated) {
            onMediaItemCreated(result.data.item);
          } else if (!result.data?.success) {
            setError(result.data?.error || 'Unknown Error');
          }
        })
        ?.catch((error: Error) => {
          setError(error?.message || error?.toString() || 'Unknown error');
        });
    },
    [apiBaseUrl, employerId, run, folderId, onMediaItemCreated, setError],
  );

  return {
    error,
    loading,
    createMediaItem,
    uploadProgress: loading ? uploadProgress : 0,
  };
};
