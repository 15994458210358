import React, { useCallback, useEffect } from 'react';
import { GenericFormInputProps } from './types';
import { useField } from 'formik';
import { FormInputContainer } from './form-input-container';

export type FormOtpInputProps = Omit<GenericFormInputProps, 'renderBefore' | 'renderAfter' | 'icon' | 'placeholder'> & {
  className?: string;
  codeLength: number;
};

/**
 *
 * A component that renders an OTP input field, with {codeLength} number of digits.
 * Handles pasting of OTP codes, autofocuses the next input field when a digit is entered, autofocuses the previous input field when backspace is pressed, and updates formik value only when all digits are entered.
 *
 */
export const FormOtpInput = ({
  className,
  containerClassName = 'form-input-container otp-input',
  containerTestId,
  name,
  label,
  tooltip,
  required,
  optional,
  testId,
  codeLength,
}: FormOtpInputProps) => {
  const refs = React.useRef<HTMLInputElement[]>([]);
  const [{ value }, { error, touched }, { setValue }] = useField(name);

  const id = (index: number) => `${name}-${index}`;

  const onChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const nextIndex = index + 1;
    const prevIndex = index - 1;

    if (value.length > 1) {
      if (nextIndex < codeLength) {
        refs.current[nextIndex].value = value[1];
        refs.current[nextIndex].focus();
      }

      refs.current[index].value = value[0];
    } else if (value.length === 0) {
      if (prevIndex >= 0) {
        refs.current[prevIndex].focus();
      }
    }

    const otp = refs.current.map((ref) => ref.value).join('');
    if (otp.length === codeLength) {
      setValue(otp);
    } else {
      setValue('');
    }
  };

  // Handle pasting of OTP codes
  const onPaste = useCallback(
    (event: React.ClipboardEvent) => {
      event.preventDefault();
      const paste = event.clipboardData.getData('text/plain');
      const matchesOtp = paste.match(/^\d+$/) && paste.length === codeLength;
      if (matchesOtp) {
        setValue(paste);
        refs.current[refs.current.length - 1].focus();
      }
    },
    [codeLength, setValue],
  );

  // Handle backspace, left arrow, and right arrow keys
  const onKeyDown = useCallback(
    (index: number) => (event: React.KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        const prevIndex = index - 1;
        if (prevIndex >= 0 && refs.current[prevIndex]) {
          event.preventDefault();
          refs.current[prevIndex].focus();
        }
      } else if (event.key === 'ArrowRight') {
        const nextIndex = index + 1;
        if (nextIndex < codeLength && refs.current[nextIndex]) {
          event.preventDefault();
          refs.current[nextIndex].focus();
        }
      } else if (event.key === 'Backspace') {
        const { value } = event.target as HTMLInputElement;
        if (value.length === 0) {
          event.preventDefault();
          const prevIndex = index - 1;
          if (prevIndex >= 0 && refs.current[prevIndex]) {
            refs.current[prevIndex].value = '';
            refs.current[prevIndex].focus();
          }
        }
      }
    },
    [],
  );

  useEffect(() => {
    if (value) {
      refs.current.forEach((ref, idx) => {
        ref.value = value[idx] || '';
      });
    }
  }, [value]);

  return (
    <div className={className}>
      <FormInputContainer
        name={name}
        error={error}
        touched={touched}
        label={label}
        tooltip={tooltip}
        required={required}
        optional={optional}
        testId={testId}
        containerClassName={containerClassName}
        containerTestId={containerTestId}
        htmlFor={name}
      >
        <fieldset className="flex flex-row items-center justify-center mx-auto max-w-xs space-x-2">
          {Array.from(Array(codeLength).keys()).map((i) => (
            <div className="w-16 h-16" key={i}>
              <input
                ref={(ref) => {
                  if (ref) {
                    refs.current[i] = ref;
                  }
                }}
                name={id(i)}
                type="number"
                className="otp-input"
                tabIndex={i + 1}
                maxLength={2}
                min={0}
                onChange={onChange(i)}
                onPaste={onPaste}
                onKeyDown={onKeyDown(i)}
              />
            </div>
          ))}
        </fieldset>
      </FormInputContainer>
    </div>
  );
};
